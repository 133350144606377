<template>
    <div style="height: inherit; background-color: var(--v-greyRaised-base) !important">
        <v-row no-gutters>
          <v-col cols="12" sm="6" md="2" lg="2" class="px-2">
              <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title> Sales </v-list-item-title>
                    <v-list-item-subtitle v-if="unitSales !== 0 && isFinite(unitSales) && !isNaN(unitSales)">
                      Per Unit: {{ getSymbol(orderFinancialSetting.baseCurrency) }} {{ formatNumber(unitSales) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="chipAction">
                    <v-chip> + {{ getSymbol(orderFinancialSetting.baseCurrency) }} {{ formatNumber(totalSales) }} </v-chip>
                  </v-list-item-action>
              </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="2" lg="2" class="px-2">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> Cost </v-list-item-title>
                  <v-list-item-subtitle v-if="unitCost !== 0 && isFinite(unitCost) && !isNaN(unitCost)">
                    Per Unit: - {{ getSymbol(orderFinancialSetting.baseCurrency) }} {{ formatNumber(unitCost) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="chipAction">
                  <v-chip>
                    - {{ getSymbol(orderFinancialSetting.baseCurrency) }} {{ formatNumber(totalCost) }}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="2" lg="2" class="px-2">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> Profit/Loss </v-list-item-title>
                  <v-list-item-subtitle v-if="unitProfit !== 0 && isFinite(unitProfit) && !isNaN(unitProfit)">
                    Per Unit: {{ getSymbol(orderFinancialSetting.baseCurrency) }} {{ formatNumber(unitProfit) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="chipAction">
                  <v-chip v-if="totalProfit > 0" color="#4CAF50" outlined style="background-color: rgba(76, 175, 80, 0.25) !important" >
                    {{ getSymbol(orderFinancialSetting.baseCurrency) }} {{ formatNumber(totalProfit) }}
                  </v-chip>
                  <v-chip v-else color="#F44336" outlined style="background-color: rgba(244, 67, 54, 0.25) !important" >
                    {{ getSymbol(orderFinancialSetting.baseCurrency) }} {{ formatNumber(totalProfit) }}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="2" lg="2" class="px-2">
              <v-list-item>
                <v-list-item-action class="chipAction">
                  <v-avatar size="32" :color="$store.state.currentOrg.logo ? 'white' : 'secondary'" >
                    <v-img v-if="$store.state.currentOrg.logo" :src="$store.state.currentOrg.logo" contain ></v-img>
                    <h3 v-else> {{ $store.state.currentOrg.name.charAt(0) }} </h3>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> My Profit/Loss </v-list-item-title>
                  <v-list-item-subtitle v-if="myUnitProfit !== 0 && isFinite(myUnitProfit) && !isNaN(myUnitProfit)">
                    Per Unit: {{ getSymbol(orderFinancialSetting.baseCurrency) }} {{ formatNumber(myUnitProfit) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="chipAction">
                  <v-chip v-if="myTotalProfit > 0" color="#4CAF50" outlined style="background-color: rgba(76, 175, 80, 0.25) !important" >
                    {{ getSymbol(orderFinancialSetting.baseCurrency) }} {{ formatNumber(myTotalProfit) }}
                  </v-chip>
                  <v-chip v-else color="#F44336" outlined style="background-color: rgba(244, 67, 54, 0.25) !important" >
                    {{ getSymbol(orderFinancialSetting.baseCurrency) }} {{ formatNumber(myTotalProfit) }}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
          </v-col>
          <v-col cols="12" sm="0" md="1" lg="1" class="px-2"></v-col>
          <v-col cols="12" sm="10" md="3" lg="3" class="px-2 d-flex justify-end align-center">
              <v-autocomplete outlined rounded class="stop-wrap" prepend-inner-icon="filter_alt" dense label="Order Item" hide-details v-model="filterBookingContainerId" :filter="filterObject" :items="containers" clearable item-text="ctoNo" item-value="id" >
                <template v-slot:selection="data">
                  {{ data.item.ctoNo }}
                  <span v-if="data.item.containerNo"> - {{ data.item.containerNo }}</span>
                </template>
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.ctoNo }}</v-list-item-title>
                    <v-list-item-subtitle v-if="data.item.containerNo">
                      {{ data.item.containerNo }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
          </v-col>
        </v-row>
        <div style="height:calc(100vh - 120px) !important; overflow-y: scroll; contain: content">
        <v-row no-gutters class="pb-4">
        <v-col cols="12" sm="12" class="px-2">
          <v-card class="mb-2" :loading="loadingSales" style=" border-radius: 10px; background-color: var(--v-greyRaised-lighten1) !important;">
            <v-card-text class="my-0 py-0">
              <v-list subheader dense>
                <v-subheader style="font-size: 16px">
                  <v-icon color="grey" class="mr-2">handshake</v-icon>
                  Sales Items
                  <v-btn class="ml-2" small rounded style="text-transform: none" text color="primary" @click="addSalesItem" >
                    Add Manual Item
                    <v-icon right>add_circle_outline</v-icon>
                  </v-btn>
                  <v-btn class="ml-2" text rounded style="text-transform: none" small color="orange" @click="assignFromStockModal = true" >
                    Add from stock
                    <v-icon right>add_circle_outline</v-icon>
                  </v-btn>
                  <v-btn class="ml-2" text rounded style="text-transform: none" small color="teal" @click="generateInvoice()" >
                    Generate Invoice
                    <v-icon right>post_add</v-icon>
                  </v-btn>
                </v-subheader>
                <v-divider></v-divider>
                <table style=" width: 100%; font-size: 12px; border: solid 0.5px; border-color: grey; border-collapse: collapse; " cellpadding="10" :key="orderItemKey">
                  <thead style="border-bottom: 0.5px solid grey">
                    <tr>
                      <th style="border-right: 0.25px solid grey">Order Item</th>
                      <th style="border-right: 0.25px solid grey">Reference</th>
                      <th style="border-right: 0.25px solid grey">Product/Variety</th>
                      <th style="border-right: 0.25px solid grey">Pack</th>
                      <th style="border-right: 0.25px solid grey">Grade</th>
                      <th style="border-right: 0.25px solid grey">Size</th>
                      <th style="border-right: 0.25px solid grey">Inventory</th>
                      <th style="border-right: 0.25px solid grey">PHC</th>
                      <th style="border-right: 0.25px solid grey">PUC</th>
                      <th style="border-right: 0.25px solid grey">Date</th>
                      <th style="border-right: 0.25px solid grey">Units</th>
                      <th style="border-right: 0.25px solid grey">Unit Price</th>
                      <th style="border-right: 0.25px solid grey">Total</th>
                      <th style="border-right: 0.25px solid grey">Base Price</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody v-for="(saleType, index) in salesTypes" :key="index">
                    <tr style=" width: 100%; background-color: var(--v-toolbar-lighten3) !important; " >
                      <td style="font-weight: bold; font-size: 14px" :colspan="15" >
                        <span class="ml-2">{{ saleType.name }}</span>
                      </td>
                    </tr>
                    <tr class="text-center" v-for="(sale, index) in filterSalesItems.filter( (x) => x.type == saleType.value )" :key="index" style="font-size: 11px; cursor: pointer" @click="editSalesItem(sale)">
                      <td class="text-left">
                        <v-tooltip top v-if="sale && sale.orderSaleStocks.length > 0" >
                          <template v-slot:activator="{ on }">
                            <v-icon small v-on="on" color="primary" class="ml-1 mr-1" >link</v-icon >
                          </template>
                          {{ sale.orderSaleStocks.length }} Linked Pallets
                        </v-tooltip>
                        <v-tooltip top v-if="sale && sale.invoice">
                          <template v-slot:activator="{ on }">
                            <v-icon small v-on="on" color="success" class="mx-1" >attach_money</v-icon >
                          </template>
                          Invoice: {{ sale.invoice.invoiceNumber }}
                        </v-tooltip>
                        <v-tooltip top v-else>
                          <template v-slot:activator="{ on }">
                            <v-icon small v-on="on" color="grey" class="mx-1" >money_off</v-icon >
                          </template>
                          Not Invoiced
                        </v-tooltip>
                        <v-tooltip v-if="sale.note" top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small color="orange" class="mr-1" >edit_note</v-icon >
                          </template>
                          <span style="font-size: 11px" >Notes: {{ sale.note }}</span >
                        </v-tooltip>
                        <v-tooltip v-else top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small color="grey" class="mr-1" >edit_note</v-icon >
                          </template>
                          <span style="font-size: 11px">No Notes</span>
                        </v-tooltip>
                        <span v-if=" sale.bookingContainer && sale.bookingContainer.containerNo " >{{ sale.bookingContainer.containerNo }}</span >
                        <span v-else-if=" sale.bookingContainer && sale.bookingContainer.ctoNo " >{{ sale.bookingContainer.ctoNo }}</span >
                      </td>
                      <td>{{ sale.reference }}</td>
                      <td>
                        <span v-if="sale.product && sale.variety" >{{ sale.product.name }}: {{ sale.variety.name }}</span >
                      </td>
                      <td>{{ sale.packCode }}</td>
                      <td>{{ sale.grade }}</td>
                      <td>{{ sale.countSize }}</td>
                      <td>{{ sale.inventoryCode }}</td>
                      <td>{{ sale.phc }}</td>
                      <td>{{ sale.puc }}</td>
                      <td>{{ sale.date }}</td>
                      <td>{{ sale.unitQuantity }}</td>
                      <td :class=" !sale.isDiscarded ? 'text-right px-1' : 'text-center' " >
                        <div v-if="!sale.isDiscarded">
                          {{ getSymbol(sale.currency) }}
                          {{ formatNumber(sale.unitPrice) }}
                        </div>
                        <v-tooltip v-else top>
                          <template v-slot:activator="{ on }">
                            <v-chip outlined x-small v-on="on" color="red">
                              <v-icon small left>remove_circle</v-icon>
                              Discarded
                            </v-chip>
                          </template>
                          <span>{{ sale.note }}</span>
                        </v-tooltip>
                      </td>
                      <td class="text-right px-1">
                        <div v-if="!sale.isDiscarded">
                          {{ getSymbol(sale.currency) }}
                          {{ formatNumber(sale.total) }}
                        </div>
                      </td>
                      <td class="text-right px-1">
                        <div v-if="!sale.isDiscarded">
                          {{ getSymbol(orderFinancialSetting.baseCurrency) }}
                          {{ formatNumber(sale.baseAmount) }}
                        </div>
                      </td>
                      <td class="text-right px-1">
                        <div v-if="!sale.isDiscarded">
                          {{ getSymbol(orderFinancialSetting.baseCurrency) }}
                          {{ formatNumber(sale.baseTotal) }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr v-if="salesItems.length == 0">
                      <td class="text-center" :colspan="15">
                        <span style="font-size: 10px; color: grey">No data</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-list>
            </v-card-text>
          </v-card>
          <v-card :loading="loadingBilling" class="mb-2" style=" border-radius: 10px; background-color: var(--v-greyRaised-lighten1) !important;">
            <v-card-text class="my-0 py-0">
              <v-list subheader dense>
                <v-subheader style="font-size: 16px">
                  <v-icon color="grey" class="mr-2">agriculture</v-icon>
                  Supply Costs
                </v-subheader>
                <v-divider></v-divider>
                <table style=" width: 100%; font-size: 12px; border: solid 0.5px; border-color: grey; border-collapse: collapse; " cellpadding="10" >
                  <thead style="border-bottom: 0.5px solid grey">
                    <th style="border-right: 0.25px solid grey; width: 120px">
                      Order Item
                    </th>
                    <th style="border-right: 0.25px solid grey; width: 120px">
                      Supplier
                    </th>
                    <th style="border-right: 0.25px solid grey; width: 120px">
                      Date
                    </th>
                    <th style="border-right: 0.25px solid grey; width: 120px">
                      Units
                    </th>
                    <th style="border-right: 0.25px solid grey; width: 120px">
                      Currency
                    </th>
                    <th style="border-right: 0.25px solid grey; width: 120px">
                      Amount
                    </th>
                    <th style="border-right: 0.25px solid grey; width: 120px">
                      Unit Cost
                    </th>
                    <th style="border-right: 0.25px solid grey; width: 120px">
                      Unit Sale
                    </th>
                    <th style="border-right: 0.25px solid grey; width: 120px">
                      Unit Profit/Loss
                    </th>
                  </thead>
                  <tbody>
                    <tr v-for="sale in filterSaleCostItems" :key="sale.id" @click="editSalesItem(sale)" style="font-size: 11px; cursor: pointer" >
                      <td class="text-center">
                        <v-tooltip top v-if="sale && sale.orderSaleStocks.length > 0" >
                          <template v-slot:activator="{ on }">
                            <v-icon small v-on="on" color="primary" class="mr-2" >link</v-icon >
                          </template>
                          {{ sale.orderSaleStocks.length }} Linked Pallets
                        </v-tooltip>
                        <span v-if=" sale.bookingContainer && sale.bookingContainer.containerNo " >{{ sale.bookingContainer.containerNo }}</span >
                        <span v-else-if=" sale.bookingContainer && sale.bookingContainer.ctoNo " >{{ sale.bookingContainer.ctoNo }}</span >
                      </td>
                      <td class="text-left px-1" style="width: 120px">
                        <v-chip small outlined style="border: none" v-if="sale.supplier" >
                          <v-avatar left :color="sale.supplier.logo ? 'white' : 'secondary'" size="24" >
                            <v-img v-if="sale.supplier.logo" :src="sale.supplier.logo" contain ></v-img>
                            <h3 v-else> {{ sale.supplier.name.charAt(0) }} </h3>
                          </v-avatar>
                          {{ sale.supplier.alias ? sale.supplier.alias : sale.supplier.name }}
                        </v-chip>
                      </td>
                      <td class="text-center">{{ sale.date }}</td>
                      <td class="text-center">{{ sale.unitQuantity }}</td>
                      <td class="text-center">{{ sale.costCurrency }}</td>
                      <td class="text-right px-1">
                        <div v-if="!sale.isDiscarded">
                          {{ getSymbol(sale.currency) }}
                          {{ formatNumber(sale.unitCost) }}
                        </div>
                        <v-tooltip v-else top>
                          <template v-slot:activator="{ on }">
                            <v-chip outlined x-small v-on="on" color="red">
                              <v-icon small left>remove_circle</v-icon>
                              Discarded
                            </v-chip>
                          </template>
                          <span>{{ sale.note }}</span>
                        </v-tooltip>
                      </td>
                      <td class="text-right px-1">
                        <div v-if="!sale.isDiscarded">
                          {{ getSymbol(sale.currency) }}
                          {{ formatNumber(sale.baseCost) }}
                        </div>
                      </td>
                      <td class="text-right px-1">
                        <div v-if="!sale.isDiscarded">
                          {{ getSymbol(orderFinancialSetting.baseCurrency) }}
                          {{ formatNumber(sale.baseAmount) }}
                        </div>
                      </td>
                      <td class="text-right px-1">
                        <div v-if="!sale.isDiscarded">
                          {{ getSymbol(orderFinancialSetting.baseCurrency) }}
                          {{ formatNumber(sale.unitProfit) }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr v-if="filterSaleCostItems.length == 0">
                      <td class="text-center" :colspan="13">
                        <span style="font-size: 10px; color: grey">No data</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-list>
            </v-card-text>
          </v-card> 
          <v-card :loading="loadingBilling" style=" border-radius: 10px; background-color: var(--v-greyRaised-lighten1) !important; " >
            <v-card-text class="my-0 py-0">
              <v-list subheader dense>
                <v-subheader style="font-size: 16px">
                  <v-icon color="grey" class="mr-2">payment</v-icon>
                  Cost Items
                  <v-spacer></v-spacer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-switch v-on="on" v-model="scaffolding" label="Focus" ></v-switch>
                    </template>
                    <span>Show only items with charges attached to them</span>
                  </v-tooltip>
                </v-subheader>
                <v-divider></v-divider>
                <CostingSheet
                  :orderId="order.id"
                  :financeChargeItems="financeChargeItems"
                  :baseCurrency="orderFinancialSetting.baseCurrency"
                  :containers="containers"
                  :orderDocuments="orderDocuments"
                  :organisations="organisations"
                  :billingItems="billingItems"
                  :toggleScaffolding="scaffolding"
                  @updateBillingItems="updateBillingItems"
                  :documentViewModal="documentViewModal"
                />
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        </v-row>
      </div>

      <div :class="['sideDrawer', { show: sideDrawer }]">
        <v-card style="width: 100%; height: 100%; background: var(--v-greyDarker-base) !important;" tile flat>
          <v-subheader style="font-size: 16px">
            <v-icon color="grey" class="mr-2">hub</v-icon>
                Account Sales 
                <v-spacer></v-spacer>
                <v-btn icon small v-if="accountSaleNeedsUpdatingRates() || accountSaleNeedsUpdatingSuppliers()">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">``
                      <v-icon v-on="on" color="yellow">warning</v-icon>
                    </template>
                    <span>Regenerate Account sales to apply {{accountSaleNeedsUpdatingSuppliers() ? 'supplier(s) change':'new rate(s)'}}</span>
                  </v-tooltip>
                </v-btn>
                <v-btn icon :disabled="canGenerateAccountSale()" color="primary" @click="createAccountSale()" :loading="creatingAccountSale">
                  <v-icon>add_circle_outline</v-icon>
                </v-btn>
              </v-subheader>
              <v-divider></v-divider>
                <v-list-item v-if="salesSuppliers.length == 0">
                  <v-list-item-content class="text-center">
                    <span style="color: grey; font-size: 12px">No data</span>
                  </v-list-item-content>
                </v-list-item>
                  <div v-for="(item, index) of salesSuppliers" :key="item.id" class="px-2">
                    <v-chip small outlined style="border: none">
                      <v-avatar left size="24" :color="item.logo ? 'white' : 'secondary'" >
                        <v-img v-if="item.logo" :src="item.logo" contain ></v-img>
                        <h3 v-else>{{ item.name.charAt(0) }}</h3>
                      </v-avatar>
                      {{ item.alias ? item.alias : item.name }}
                    </v-chip>
                    <v-list dense>
                      <v-list-item class="my-0 py-0" v-if="item.currency !== orderFinancialSetting.baseCurrency" @click="viewSupplierRate(item, index)">
                        <v-list-item-content>
                          <v-list-item-title class="my-0 py-0" style="font-size: 11px">
                            Rate <v-icon v-if="item.currency !== orderFinancialSetting.baseCurrency && !item.exchangeRate" color="red">warning</v-icon>
                          </v-list-item-title>
                          <v-list-item-subtitle class="my-0 py-0" style="font-size: 11px">
                            {{item.exchangeRate && item.currency ? `${orderFinancialSetting.baseCurrency}/${item.currency} ${formatNumberRate(1 / item.exchangeRate)}`: ''}}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="accountSales.length == 0">
                        <v-list-item-content class="text-center">
                          <span style="color: grey; font-size: 12px">No data</span>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-for="account of applicableAccountSale(item.organisationId, accountSales)" :key="account.organisationId" style="border-bottom: 0.25px solid grey" class="" @click="downloadAccountSale(account)">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ account.accountSaleNo }}
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 11px" v-if=" account.bookingContainer && account.bookingContainer.containerNo">
                            {{ account.bookingContainer.containerNo }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle style="font-size: 11px">
                            {{ account.quantity }} Units |
                            {{ account.soldQuantity }} Sold
                            <span v-if="account.discardedQuantity > 0">
                              | {{ account.discardedQuantity }} Discarded</span >
                          </v-list-item-subtitle>
                          <v-list-item-subtitle style="font-size: 11px">
                            {{ account.date }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action class="d-flex flex-column align-end">
                          <v-chip v-if="parseFloat(account.unitProfit) > 0" color="#4CAF50" outlined small class="mb-2" style="background-color: rgba(76, 175, 80, 0.15) !important" >
                          {{ getSymbol(account.currency) }}
                            {{ account.unitProfit }} / unit
                          </v-chip>
                          <v-chip v-else color="#F44336" outlined small class="mb-2" style="background-color: rgba(244, 67, 54, 0.15) !important" >
                            {{ getSymbol(account.currency) }}
                            {{ account.unitProfit }} / unit
                          </v-chip>
                          <v-chip v-if="account.unitProfitSupplier && parseFloat(account.unitProfitSupplier) > 0 && account.supplierCurrency !== account.currency" color="#4CAF50" outlined small style="background-color: rgba(76, 175, 80, 0.15) !important" >
                          {{ getSymbol(account.supplierCurrency) }}
                            {{ account.unitProfitSupplier }} / unit
                          </v-chip>
                          <v-chip v-else-if="account.unitProfitSupplier && account.supplierCurrency !== account.currency" color="#F44336" outlined small style="background-color: rgba(244, 67, 54, 0.15) !important" >
                            {{ getSymbol(account.supplierCurrency) }}
                            {{ account.unitProfitSupplier }} / unit
                          </v-chip>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </div>
              <v-divider></v-divider>
              <v-subheader style="font-size: 16px">
                <v-icon color="grey" class="mr-2">list</v-icon>
                Packing Lists <v-spacer></v-spacer>
                <v-btn icon color="primary" @click="packingListModal = true" :loading="creatingPackingList">
                  <v-icon>add_circle_outline</v-icon>
                </v-btn>
              </v-subheader>
              <v-divider></v-divider>
              <div class="px-2">
                <v-list-item v-if="packingLists.length == 0">
                  <v-list-item-content class="text-center">
                    <span style="color: grey; font-size: 12px">No data</span>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-for="pl in packingLists" :key="pl.id">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ pl.containerNo }}
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 11px">
                      {{ formatDate(pl.packingListDocument.createdAt) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-container>
                      <v-row justify="center">
                        <v-tooltip top v-if="pl.packingListDocument">
                          <template v-slot:activator="{ on }">
                            <v-btn icon small @click="downloadPackingList(pl.packingListDocument)" v-on="on" >
                              <v-icon small>download</v-icon>
                            </v-btn>
                          </template>
                          <span style="font-size: 12px">Download PDF</span>
                        </v-tooltip>
                        <v-tooltip top v-if="pl.packingListExcelDocument">
                          <template v-slot:activator="{ on }">
                            <v-btn icon small @click="downloadPackingList(pl.packingListExcelDocument)" v-on="on" color="green">
                              <v-icon small>browser_updated</v-icon>
                            </v-btn>
                          </template>
                          <span style="font-size: 12px">Download Excel</span>
                        </v-tooltip>
                      </v-row>
                    </v-container>
                  </v-list-item-action>
                </v-list-item>
              </div>
              <v-divider></v-divider>
              <v-subheader style="font-size: 16px">
                <v-icon color="grey" class="mr-2">account_balance</v-icon>
                Invoices 
                <v-spacer></v-spacer>
                <v-chip small v-if="orderInvoices.length > 0">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          Total: {{ getSymbol(orderFinancialSetting.baseCurrency) }}{{ totalInvoiced.totalInclVAT > 0 ?formatNumber(totalInvoiced.totalInclVAT): totalInvoiced.totalExclVAT > 0 ? formatNumber(totalInvoiced.totalExclVAT): 0}}
                        </span>
                    </template>
                    <span v-if="totalInvoiced.totalInclVAT > 0">
                      Total Invoiced Amount (Incl. VAT)</span>
                    <span v-else-if="totalInvoiced.totalExclVAT > 0">
                      Total Invoiced Amount (Excl. VAT)</span>
                  </v-tooltip>
                </v-chip>
                <v-btn icon color="primary" @click="addInvoice()">
                  <v-icon>add_circle_outline</v-icon>
                </v-btn>
              </v-subheader>
              <v-divider></v-divider>
              <div class="px-2">
                  <v-list-item v-if="orderInvoices.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey; font-size: 12px">No data</span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-for="invoice in orderInvoices" :key="invoice.id" @click="editInvoice(invoice)" style="border-bottom: 0.25px solid grey">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ invoice.invoiceNumber }}
                        <v-chip v-if="invoice.status" x-small :color="invoiceColor(invoice.status)" >{{ invoice.status }}</v-chip >
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="invoice.reference">
                        <v-icon x-small color="grey" class="mr-2" >description</v-icon >
                        {{ invoice.reference }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        Invoice Date: {{ formatDate(invoice.date) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        Due Date: {{ formatDate(invoice.dueDate) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip small>
                        {{ getSymbol(invoice.currencyCode) }}
                        {{ formatNumber(invoice.totalInclVAT) }}
                      </v-chip>
                      <span v-if="invoice.amountDue != 0" style="font-size: 11px">
                        Due: {{ getSymbol(invoice.currencyCode) }}
                        {{ invoice.amountDue}}
                      </span>
                      <span style="font-size: 11px">
                        Paid: {{ getSymbol(invoice.currencyCode) }}
                        {{ formatNumber(invoice.amountPaid) }}
                      </span>
                      <span  style="font-size: 11px">
                        Credited: {{ getSymbol(invoice.currencyCode) }}
                        {{ formatNumber(invoice.amountCredited) }}
                      </span>
                    </v-list-item-action>
                  </v-list-item>
              </div>
              <v-divider></v-divider>
              <v-subheader style="font-size: 16px">
                <v-icon color="grey" class="mr-2">account_balance</v-icon>
                Credit Notes 
                <v-spacer></v-spacer>
                <v-chip  small v-if="orderCreditNotes.length > 0">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                      <span v-on="on">
                        Total: {{ getSymbol(orderFinancialSetting.baseCurrency) }}{{ formatNumber(totalCredited)}}
                      </span>
                    </template>
                    <span>Total Credited Amount (Incl. VAT)</span>
                    </v-tooltip>
                  </v-chip>
                <v-btn icon color="primary" @click="addCreditNote()">
                  <v-icon>add_circle_outline</v-icon>
                </v-btn>
              </v-subheader>
              <v-divider></v-divider>
              <v-list-item v-if="orderCreditNotes.length == 0">
                <v-list-item-content class="text-center">
                  <span style="color: grey; font-size: 12px">No data</span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="creditNote in orderCreditNotes"
                :key="creditNote.id"
                style="border-bottom: 0.25px solid grey"
                @click="editCreditNote(creditNote)"
                >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ creditNote.creditNoteNumber }}
                    <v-chip
                      v-if="creditNote.status"
                      x-small
                      :color="invoiceColor(creditNote.status)"
                      >{{ creditNote.status }}</v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="creditNote.reference">
                    <v-icon x-small color="grey" class="mr-2"
                      >description</v-icon
                    >
                    {{ creditNote.reference }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    Issue Date: {{ formatDate(creditNote.date) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip small>
                    {{ getSymbol(creditNote.currencyCode) }}
                    {{ formatNumber(creditNote.totalInclVAT) }}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>
              <v-subheader style="font-size: 16px">
                <v-icon color="grey" class="mr-2">description</v-icon>
                Documents <v-spacer></v-spacer>
                <v-btn icon color="primary" @click="addFiles()">
                  <v-icon>add_circle_outline</v-icon>
                </v-btn>
              </v-subheader>
              <v-divider v-if="!loadingOrderDocuments"></v-divider>
              <v-progress-linear indeterminate v-if="loadingOrderDocuments" height="1"></v-progress-linear>
              <div class="px-2">
                  <v-list-item v-if="orderDocuments.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey; font-size: 12px">No documents</span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-for="document in orderDocuments" :key="document.id" @click="viewDocument(document)">
                      <v-list-item-content>
                          <v-list-item-title>
                            {{ document.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="document.reference">
                            {{ document.reference }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="document.date">
                            {{ formatDate(document.date) }}
                          </v-list-item-subtitle>
                      </v-list-item-content>
                  </v-list-item>
              </div>
          </v-card>
      </div>

      <v-dialog v-model="salesModal" :width="documentViewModal ? '58%':'1000px'" :content-class="documentViewModal ? 'dialogRight': ''">
        <v-card>
          <v-toolbar flat dense>
            <v-toolbar-title>
              <span v-if="salesItem.id">Edit</span>
              <span v-else>Add</span>
              Sales Item
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="saveSalesItem" :loading="savingSalesItem" icon color="primary" :disabled=" !salesItem.productId || !salesItem.varietyId || (!salesItem.isDiscarded ? !salesItem.unitPrice : false) || !salesItem.unitQuantity " >
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn icon color="red" v-if="salesItem.id" @click="confirmDeleteSale = true" >
              <v-icon>delete</v-icon>
            </v-btn>
            <v-btn text @click="salesModal = false">X</v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row align="baseline">
                <v-col cols="12" sm="4">
                  <v-card flat>
                    <v-toolbar flat dense>
                      <v-toolbar-title>Detail</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" class="py-0">
                          <v-text-field label="Reference" class="my-1" v-model="salesItem.reference" outlined dense hide-details ></v-text-field>
                          <v-select v-if=" orderFinancialSetting.type == 'MGP' || orderFinancialSetting.type == 'FULL_CONSIGNMENT' "
                            hide-details v-model="salesItem.type" class="my-1 py-1" label="Type" dense outlined item-text="name" item-value="value"
                            :items="[ { name: 'Fixed Price', value: 'FIXED' }, { name: 'Minimum Guaranteed Price', value: 'MGP' }, { name: 'Market Sales Price', value: 'MARKET_RATE'}]"
                          ></v-select>
                          <v-col cols="12" class="my-0 py-0">
                            <v-row>
                              <v-col cols="12" sm="6" class="ml-0 pl-0">
                                <v-autocomplete :items="orderDocuments" class="my-1" label="Document" outlined dense hide-details item-text="name" item-value="id" v-model="salesItem.shipmentDocumentId" >
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto" >
                                  <template v-slot:activator="{ on }">
                                    <v-list-item v-on="on" class="text-left" style="height: 40px" >
                                      <v-list-item-icon>
                                        <v-icon color="primary">event</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title style="font-size: 13px" >
                                          {{ formatDate(salesItem.date) }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle style="font-size: 13px" >
                                          Date
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                  <v-date-picker v-model="salesItem.date" no-title scrollable >
                                  </v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card flat>
                    <v-toolbar flat dense>
                      <v-toolbar-title>Sales</v-toolbar-title
                      ><v-spacer></v-spacer>
                      <v-switch dense v-model="salesItem.isDiscarded" label="Discarded" color="red" hide-details ></v-switch>
                    </v-toolbar>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" class="my-0 py-0">
                          <v-row>
                            <v-col cols="12" sm="4" class="mr-0 pr-0">
                              <v-text-field :label="`Units`" outlined dense class="my-1" type="number" v-model="salesItem.unitQuantity" hide-details @change="updateUnitInput()" ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" class="ml-0 pl-0">
                              <v-text-field v-if=" !salesItem.isDiscarded && salesItem.currency != salesItem.baseCurrency " label="Applied Exchange Rate" outlined hide-details dense class="my-1" :key="exKey" v-model="inverseExchangeRate" >
                                <template v-slot:append>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon v-bind="attrs" v-on="on" @click="applyCurrentRate"> arrow_back</v-icon>
                                    </template>
                                    <span>Apply current rate</span>
                                  </v-tooltip>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" class="ml-0 pl-0">
                              <v-text-field v-if=" !salesItem.isDiscarded && salesItem.currency != salesItem.baseCurrency " disabled label="Current Rate" outlined hide-details dense class="my-1" :loading="loadingCurrency" v-model="currentExchangeRate" ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" class="my-0 py-0">
                          <v-row>
                            <v-col cols="12" sm="5" class="mr-0 pr-0">
                              <v-autocomplete v-if="!salesItem.isDiscarded" label="Currency" outlined dense class="my-1" hide-details :items="currencies" v-model="salesItem.currency" ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="8" class="ml-0 pl-0">
                              <v-text-field v-if="!salesItem.isDiscarded" label="Unit Price*" :prefix="getSymbol(salesItem.currency)" outlined class="my-1" hide-details dense type="number" v-model="salesItem.unitPrice" ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" class="my-0 py-0">
                          <v-row v-if=" !salesItem.isDiscarded && salesItem.currency != salesItem.baseCurrency " >
                            <v-col cols="12" sm="4" class="mr-0 pr-0">
                              <v-autocomplete disabled label="Currency" outlined dense class="my-1" hide-details :items="currencies" v-model="salesItem.baseCurrency" ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="7" class="ml-0 pl-0">
                              <v-text-field label="Unit Base Price*" :prefix="getSymbol(salesItem.baseCurrency)" outlined class="my-1" hide-details dense type="number" v-model="salesItem.baseAmount" ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
  
                <v-col cols="12" sm="4">
                  <v-card flat>
                    <v-toolbar flat dense>
                      <v-toolbar-title>Supplier Cost </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-switch v-if="salesItem.baseCurrency != salesItem.costCurrency" dense v-model="showSupplier" label="Show supplier" color="primary" hide-details ></v-switch>
                    </v-toolbar>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" class="my-0 py-0">
                          <v-row>
                            <v-col v-if="showSupplier" cols="12">
                              <v-autocomplete label="Supplier" class="my-1" :items="organisations.data" item-text="relatedOrganisation.name" item-value="relatedOrganisation.id" hide-details outlined v-model="salesItem.supplierId" clearable dense >
                                <template v-slot:selection="data">
                                  <v-row align="center">
                                    <v-avatar size="32" :color=" data.item && data.item.relatedOrganisation.logo ? 'white' : 'secondary' " class="mx-2" v-if="data.item" >
                                      <v-img v-if="data.item.relatedOrganisation.logo" contain :src="data.item.relatedOrganisation.logo" ></v-img>
                                      <h3 v-else> {{ data.item.relatedOrganisation.name.charAt( 0 ) }} </h3>
                                    </v-avatar>
                                    <span v-if="data.item.relatedOrganisation"> {{ data.item.relatedOrganisation.name }} </span>
                                  </v-row>
                                </template>
                                <template v-slot:item="data">
                                  <v-list-item-action>
                                    <v-avatar size="32" :color=" data.item.relatedOrganisation.logo ? 'white' : 'secondary' " >
                                      <v-img contain v-if="data.item.relatedOrganisation.logo" :src="data.item.relatedOrganisation.logo" ></v-img>
                                      <h3 v-else> {{ data.item.relatedOrganisation.name.charAt( 0 ) }} </h3>
                                    </v-avatar>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>{{ data.item.relatedOrganisation.name }}</v-list-item-title>
                                    <v-list-item-subtitle v-if="data.item.relatedOrganisation.alias" >{{ data.item.relatedOrganisation.alias }}</v-list-item-subtitle >
                                  </v-list-item-content>
                                </template>
                              </v-autocomplete>
                            </v-col>
                            <v-col v-if="!showSupplier" cols="12" sm="4">
                              <v-text-field v-if=" !salesItem.isDiscarded && salesItem.costCurrency != salesItem.baseCurrency " label="Applied Exchange Rate" outlined hide-details dense class="my-1" :key="excKey" v-model="inverseCostExchangeRate" >
                                <template v-slot:append>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon v-bind="attrs" v-on="on" @click="applyCurrentRateCost" > arrow_back</v-icon >
                                    </template>
                                    <span>Apply current rate</span>
                                  </v-tooltip>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col v-if="!showSupplier" cols="12" sm="4">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field v-if=" !salesItem.isDiscarded && salesItem.costCurrency != salesItem.baseCurrency " disabled label="Current Rate" outlined hide-details v-bind="attrs" v-on="on" dense class="my-1" :loading="loadingCurrencyCost" v-model="currentExchangeRateCost" />
                                </template>
                                <span >{{ salesItem.baseCurrency }}/{{ salesItem.costCurrency }} (+/- 5min delay)</span >
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" class="my-0 py-0">
                          <v-row>
                            <v-col cols="12" sm="5" class="mr-0 pr-0">
                              <v-autocomplete label="Currency" outlined dense class="my-1" hide-details :items="currencies" v-model="salesItem.costCurrency" ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="7" class="ml-0 pl-0">
                              <v-text-field label="Unit Cost*" :prefix="getSymbol(salesItem.costCurrency)" outlined class="my-1" hide-details dense type="number" v-model="salesItem.unitCost" ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" class="my-0 py-0">
                          <v-row v-if=" salesItem.costCurrency != salesItem.baseCurrency " >
                            <v-col cols="12" sm="5" class="mr-0 pr-0">
                              <v-autocomplete disabled label="Currency" outlined dense class="my-1" hide-details :items="currencies" v-model="salesItem.baseCurrency" ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="7" class="ml-0 pl-0">
                              <v-text-field label="Unit Base Cost" :prefix="getSymbol(salesItem.baseCurrency)" outlined class="my-1" hide-details dense type="number" v-model="salesItem.baseCost" />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="3" class="my-0 py-0">
                  <v-select hide-details class="my-1" label="Product*" style="font-size: 12px" v-model="salesItem.productId" :items="availableProducts" item-text="name" item-value="id" outlined dense />
                </v-col>
                <v-col cols="12" sm="3" class="my-0 py-0">
                  <v-select hide-details :disabled="!salesItem.productId" class="my-1" label="Variety*" v-model="salesItem.varietyId" style="font-size: 12px" :items="availableVarieties(salesItem.productId)" item-text="name" item-value="id" outlined dense />
                </v-col>
                <v-col cols="12" sm="3" class="my-0 py-0">
                  <v-text-field hide-details class="my-1" label="Pack" v-model="salesItem.packCode" outlined dense />
                </v-col>
                <v-col cols="12" sm="3" class="my-0 py-0">
                  <v-text-field hide-details class="my-1" label="Grade" v-model="salesItem.grade" outlined dense />
                </v-col>
                <v-col cols="12" sm="3" class="my-0 py-0">
                  <v-text-field hide-details class="my-1" label="Count/Size" v-model="salesItem.countSize" outlined dense />
                </v-col>
                <v-col cols="12" sm="3" class="my-0 py-0">
                  <v-text-field hide-details class="my-1" label="Inventory Code" v-model="salesItem.inventoryCode" outlined dense />
                </v-col>
                <v-col cols="12" sm="3" class="my-0 py-0">
                  <v-text-field hide-details class="my-1" label="PUC" v-model="salesItem.puc" outlined dense />
                </v-col>
                <v-col cols="12" sm="3" class="my-0 py-0">
                  <v-text-field hide-details class="my-1" label="PHC" v-model="salesItem.phc" outlined dense />
                </v-col>
                <v-col cols="12">
                  <v-textarea label="Notes" v-model="salesItem.note" outlined rows="4" auto-grow /> 
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="confirmDeleteSale" width="400px" persistent>
        <v-card>
          <v-toolbar dense flat color="transparent">
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <p style="font-size: 14px">
              Are you sure you would to delete this sales item?
            </p>
            <v-row justify="center" class="mt-5 mb-3">
              <v-btn color="red" class="mx-2" text small @click="confirmDeleteSale = false" >No</v-btn >
              <v-btn color="success" class="mx-2" text small :loading="deletingSalesItem" @click="deleteSalesItem()" >Yes</v-btn >
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="documentViewModal" fullscreen persistent>
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>
              {{ documentItem.name }}
            </v-toolbar-title>
            <v-row class="my-1">
              <v-col cols="12" sm="4">
                <v-text-field class="ml-3" label="Reference" outlined dense hide-details v-model="documentItem.reference" ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto" >
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on" class="text-left" style="height: 40px">
                      <v-list-item-content style="font-size: 12px">
                        <v-list-item-title v-if="documentItem.date">
                          {{ formatDate(documentItem.date) }}
                        </v-list-item-title>
                        <v-list-item-title v-else>- </v-list-item-title>
                        <v-list-item-subtitle> Date </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-date-picker v-model="documentItem.date" no-title scrollable>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-btn icon @click="downloadItem(documentItem)">
              <v-icon>download</v-icon>
            </v-btn>
            <v-btn icon :loading="savingDocument" @click="updateDocument(documentItem)" color="primary" >
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn @click="documentViewModal = false; creditItem = {}" icon><v-icon small>close</v-icon></v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="12" sm="5" class="text-center" v-if="documentItem.url">
                <div style="width: 100%">
                  <VuePdfEmbed :width="500" :source="documentItem.url" />
                </div>
              </v-col>
              <v-col cols="12" sm="7">
                <v-toolbar flat>
                  <v-list-item style="height: 50px">
                    <v-list-item-content>
                      <v-list-item-title> Sales </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      {{ getSymbol(orderFinancialSetting.baseCurrency) }}
                      {{ formatNumber(documentTotalSales(documentItem.id)) }}
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item style="height: 50px">
                    <v-list-item-content>
                      <v-list-item-title> Cost </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      - {{ getSymbol(orderFinancialSetting.baseCurrency) }}
                      {{ formatNumber(documentTotalCost(documentItem.id)) }}
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item style="height: 50px">
                    <v-list-item-content>
                      <v-list-item-title> Profit/Loss </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <u :style="{ 'font-weight': 'bold', color: documentTotalProfit(documentItem.id) > 0 ? '#8BC34A' : '#F44336', }" >
                        {{ getSymbol(orderFinancialSetting.baseCurrency) }}
                        {{ formatNumber(documentTotalProfit(documentItem.id)) }}
                    </u >
                    </v-list-item-action>
                  </v-list-item>
                </v-toolbar>
                <v-card class="my-1" :loading="loadingSales" style=" border-radius: 20px; background-color: var(--v-toolbar-lighten1) !important; " >
                  <v-card-text class="my-0 py-0">
                    <v-list subheader dense>
                      <v-subheader style="font-size: 16px">
                        <v-icon color="grey" class="mr-2">handshake</v-icon>
                        Sales Items
                        <v-btn class="ml-2" small rounded style="text-transform: none" text color="primary" @click=" addSalesItem({ shipmentDocumentId: documentItem.id, date: documentItem.date, reference: documentItem.reference, }) " >
                          Add Manual Item
                          <v-icon right>add_circle_outline</v-icon>
                        </v-btn>
                        <v-btn class="ml-2" text rounded style="text-transform: none" small color="orange" @click=" addSalesItemFromStock({ shipmentDocumentId: documentItem.id, date: documentItem.date, reference: documentItem.reference, }) " >
                          Add from stock
                          <v-icon right>add_circle_outline</v-icon>
                        </v-btn>
                        <v-btn class="ml-2" text rounded style="text-transform: none" small color="teal" @click="generateInvoice(documentItem.id)" >
                          Generate Invoice
                          <v-icon right>post_add</v-icon>
                        </v-btn>
                      </v-subheader>
                      <v-divider></v-divider>
                      <table style=" width: 100%; font-size: 12px; border: solid 0.5px; border-color: grey; border-collapse: collapse; " cellpadding="10" >
                        <thead style="border-bottom: 0.5px solid grey">
                          <tr>
                            <th style="border-right: 0.25px solid grey">
                              Order Item
                            </th>
                            <th style="border-right: 0.25px solid grey">
                              Reference
                            </th>
                            <th style="border-right: 0.25px solid grey">
                              Product/Variety
                            </th>
                            <th style="border-right: 0.25px solid grey">Pack</th>
                            <th style="border-right: 0.25px solid grey">Grade</th>
                            <th style="border-right: 0.25px solid grey">Size</th>
                            <th style="border-right: 0.25px solid grey">
                              Inventory
                            </th>
                            <th style="border-right: 0.25px solid grey">PHC</th>
                            <th style="border-right: 0.25px solid grey">PUC</th>
                            <th style="border-right: 0.25px solid grey">Date</th>
                            <th style="border-right: 0.25px solid grey">Units</th>
                            <th style="border-right: 0.25px solid grey">
                              Unit Price
                            </th>
                            <th style="border-right: 0.25px solid grey">Total</th>
                            <th style="border-right: 0.25px solid grey">
                              Base Price
                            </th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody v-for="(saleType, index) in documentSalesTypes( documentItem.id )" :key="index" >
                          <tr style=" width: 100%; background-color: var( --v-component-base ) !important; " >
                            <td style="font-weight: bold; font-size: 14px" :colspan="15" >
                              <v-chip class="ml-2" label outlined style="border: none" >
                                <span>{{ saleType.name }}</span>
                              </v-chip>
                            </td>
                          </tr>
                          <tr class="text-center" v-for="(sale, index) in salesItems.filter( (x) => x.type == saleType.value && x.shipmentDocumentId == documentItem.id )" :key="index" style="font-size: 11px; cursor: pointer" @click="editSalesItem(sale)" >
                            <td class="text-left">
                              <v-tooltip top v-if="sale && sale.orderSaleStocks.length > 0" >
                                <template v-slot:activator="{ on }">
                                  <v-icon small v-on="on" color="primary" class="ml-1 mr-1" >link</v-icon >
                                </template>
                                {{ sale.orderSaleStocks.length }} Linked Pallets
                              </v-tooltip>
                              <v-tooltip top v-if="sale && sale.invoice">
                                <template v-slot:activator="{ on }">
                                  <v-icon small v-on="on" color="success" class="mx-1" >attach_money</v-icon >
                                </template>
                                Invoice: {{ sale.invoice.invoiceNumber }}
                              </v-tooltip>
                              <v-tooltip top v-else>
                                <template v-slot:activator="{ on }">
                                  <v-icon small v-on="on" color="grey" class="mx-1" >money_off</v-icon >
                                </template>
                                Not Invoiced
                              </v-tooltip>
                              <v-tooltip v-if="sale.note" top>
                                <template v-slot:activator="{ on }">
                                  <v-icon v-on="on" small color="orange" class="mr-1" >edit_note</v-icon >
                                </template>
                                <span style="font-size: 11px" >Notes: {{ sale.note }}</span >
                              </v-tooltip>
                              <v-tooltip v-else top>
                                <template v-slot:activator="{ on }">
                                  <v-icon v-on="on" small color="grey" class="mr-1" >edit_note</v-icon >
                                </template>
                                <span style="font-size: 11px">No Notes</span>
                              </v-tooltip>
                              <span v-if=" sale.bookingContainer && sale.bookingContainer.containerNo " >{{ sale.bookingContainer.containerNo }}</span >
                              <span v-else-if=" sale.bookingContainer && sale.bookingContainer.ctoNo " >{{ sale.bookingContainer.ctoNo }}</span >
                            </td>
                            <td>{{ sale.reference }}</td>
                            <td>
                              <span v-if="sale.product && sale.variety" >{{ sale.product.name }}: {{ sale.variety.name }}</span >
                            </td>
                            <td>{{ sale.packCode }}</td>
                            <td>{{ sale.grade }}</td>
                            <td>{{ sale.countSize }}</td>
                            <td>{{ sale.inventoryCode }}</td>
                            <td>{{ sale.phc }}</td>
                            <td>{{ sale.puc }}</td>
                            <td>{{ sale.date }}</td>
                            <td>{{ sale.unitQuantity }}</td>
                            <td class="text-right px-1">
                              <div v-if="!sale.isDiscarded"> {{ getSymbol(sale.currency) }} {{ formatNumber(sale.unitCost) }} </div>
                              <v-tooltip v-else top>
                                <template v-slot:activator="{ on }">
                                  <v-chip outlined x-small v-on="on" color="red">
                                    <v-icon small left>remove_circle</v-icon>
                                    Discarded
                                  </v-chip>
                                </template>
                                <span>{{ sale.note }}</span>
                              </v-tooltip>
                            </td>
                            <td class="text-right px-1">
                              <div v-if="!sale.isDiscarded"> {{ getSymbol(sale.currency) }} {{ formatNumber(sale.baseCost) }} </div>
                            </td>
                            <td class="text-right px-1">
                              <div v-if="!sale.isDiscarded"> {{ getSymbol(orderFinancialSetting.baseCurrency) }} {{ formatNumber(sale.baseAmount) }} </div>
                            </td>
                            <td class="text-right px-1">
                              <div v-if="!sale.isDiscarded"> {{ getSymbol(orderFinancialSetting.baseCurrency) }} {{ formatNumber(sale.unitProfit) }} </div>
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr v-if="documentSalesTypes(documentItem.id).length == 0" >
                            <td class="text-center" :colspan="13">
                              <span style="font-size: 10px; color: grey" >No data</span >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </v-list>
                  </v-card-text>
                </v-card>
                <v-card :loading="loadingBilling" class="my-1" style=" border-radius: 20px; background-color: var(--v-toolbar-lighten1) !important; " >
                  <v-card-text class="my-0 py-0">
                    <v-list subheader dense>
                      <v-subheader style="font-size: 16px">
                        <v-icon color="grey" class="mr-2">payment</v-icon>
                        Cost Items
                        <v-spacer></v-spacer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-switch v-on="on" v-model="scaffolding" label="Focus" ></v-switch>
                          </template>
                          <span >Show only items with charges attached to them</span >
                        </v-tooltip>
                      </v-subheader>
                      <v-divider></v-divider>
                      <CostingSheet
                        :orderId="order.id"
                        :financeChargeItems="financeChargeItems"
                        :baseCurrency="orderFinancialSetting.baseCurrency"
                        :containers="containers"
                        :orderDocuments="orderDocuments"
                        :organisations="organisations"
                        :billingItems="billingItems"
                        :documentItem="documentItem"
                        :toggleScaffolding="scaffolding"
                        @updateBillingItems="updateBillingItems"
                        :documentViewModal="documentViewModal"
                      />
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="generalCreditNoteDocumentView" width="1000px">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>
              <v-chip :color="invoiceColor(documentCreditNoteItem.status)" class="mr-2" small >{{ documentCreditNoteItem.status }}</v-chip >
              {{ documentCreditNoteItem.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn :disabled="documentCreditNoteItem.status === 'AUTHORISED'" icon @click="editCreditNoteDoc(documentCreditNoteItem.id)">
            <v-icon>edit</v-icon>
          </v-btn>
          <v-btn icon @click="downloadItem(documentCreditNoteItem)">
            <v-icon>download</v-icon>
          </v-btn>
            <v-btn @click="generalCreditNoteDocumentView = false" icon color="redPop"><v-icon>close</v-icon></v-btn>
          </v-toolbar>
          <v-card-text style="max-height: 70vh; overflow-y: auto">
            <v-row justify="center">
              <v-col cols="12" sm="9" class="text-center" v-if="documentCreditNoteItem.url">
                <div style="width: 100%">
                  <VuePdfEmbed :width="500" :source="documentCreditNoteItem.url" />
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="generalInvoiceDocumentView" width="1600px"> 
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            <v-chip
              :color="invoiceColor(documentInvoiceItem.status)"
              class="mr-2"
              small
              >{{ documentInvoiceItem.status }}</v-chip
            >
            {{ documentInvoiceItem.title }} 
            <!-- <v-btn v-if="$store.state.user.id === 4255" @click=logItem(invoiceItem)><v-icon>arrow_back_ios</v-icon></v-btn> -->
                        <!-- <v-btn v-if="$store.state.user.id === 4255" @click=logItem(documentInvoiceItem)><v-icon>visibility</v-icon></v-btn> -->
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn :disabled="documentInvoiceItem.status === 'AUTHORISED'" icon @click="editInvoiceDoc(documentInvoiceItem.id)">
            <v-icon>edit</v-icon>
          </v-btn>
          <v-btn icon @click="downloadItem(documentInvoiceItem)">
            <v-icon>download</v-icon>
          </v-btn>
          <v-btn @click="generalInvoiceDocumentView = false" text>X</v-btn>
        </v-toolbar>
        <v-row class="ma-0 pa-0">
        <v-col cols="12" md="8" class="ma-0 pa-0">
          <v-card-text style="max-height: 70vh; overflow-y: auto">
            <v-row justify="center">
              <v-col cols="12" sm="8" class="text-center ma-0 pa-0" v-if="documentInvoiceItem.url">
                <div style="width: 100%">
                  <VuePdfEmbed class="text-center" :width="500" :source="documentInvoiceItem.url" />
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
        <v-col cols="12" md="4" class="ma-0 pa-0">
          <v-card tile elevation="0">
            <v-card-title>Credit Notes</v-card-title>
            <v-card-subtitle class="ma-o pa-0" v-if="documentInvoiceItem.amountCredited">
                Credited: <v-chip>{{ getSymbol(documentInvoiceItem.currencyCode) }} {{ formatNumber(documentInvoiceItem.amountCredited) }}</v-chip>
              </v-card-subtitle>
            <v-card-text>
              <v-divider></v-divider>
              <v-list class="creditNotesList" v-if="filteredCreditNotes.length > 0">
                <v-list-item  v-for="(creditNote, index) of filteredCreditNotes" :key="index"
                @click="editCreditNote(creditNote)"
                >
                  <v-list-item-content>
                  <v-list-item-title>
                    {{ creditNote.creditNoteNumber }}
                    <v-chip
                      v-if="creditNote.status"
                      x-small
                      :color="invoiceColor(creditNote.status)"
                      >{{ creditNote.status }}</v-chip
                    >
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="creditNote.reference">
                    <v-icon x-small color="grey" class="mr-2"
                      >description</v-icon
                    >
                    {{ creditNote.reference }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    Issue Date: {{ formatDate(creditNote.date) }}
                  </v-list-item-subtitle>
                  <!-- <v-list-item-subtitle>
                    Due Date: {{ formatDate(creditNote.dueDate) }}
                  </v-list-item-subtitle> -->
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip small>
                    {{ getSymbol(creditNote.currencyCode) }}
                    {{ formatNumber(creditNote.totalInclVAT) }}
                  </v-chip>
                  <!-- <span v-if="creditNote.amountDue != 0" style="font-size: 11px">
                    Due: {{ getSymbol(creditNote.currencyCode) }}
                    {{ formatNumber(creditNote.amountDue) }}
                  </span> -->
                </v-list-item-action>
                
              </v-list-item>
            </v-list>
            <v-list v-else>
              <v-list-item ><v-list-item-content class="text-center">
                        <span style="color: grey; font-size: 12px">No Credit Note(s)</span>
                      </v-list-item-content>
                    </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title class="text-center">
                <v-btn
                  color="primary"
                  text
                  rounded
                  style="text-transform: none"
                  @click="addCreditNoteFromInvoice"
                  ><v-icon left>add_circle_outline</v-icon> Add Credit Note
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>
        </v-row>
      </v-card>
    </v-dialog>
      <v-dialog v-model="assignFromStockModal" :width="documentViewModal ? '58%':'1200px'" :content-class="documentViewModal ? 'dialogRight': ''" persistent>
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>Assign From Stock</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn text @click="assignFromStockModal = false"> X </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-toolbar dense flat>
              <v-chip v-for="(header, index) in availableHeaders" :key="index" small class="mx-1" @click="addHeader(header)" >
                {{ header.text }} <v-icon color="blue" small right>add</v-icon>
              </v-chip>
            </v-toolbar>
            <table :key="productKey" style="width: 100%; max-height: 30vh; overflow-y: auto" >
              <thead style="position: sticky; width: 100%">
                <draggable
                  v-model="productSummaryHeaders"
                  tag="tr"
                  :style="{ cursor: columnDrag ? 'grabbing' : 'grab' }"
                  :animation="200"
                  ghost-class="ghost-card"
                  @start="columnDrag = true"
                  @end="columnDrag = false"
                  @change="updateHeaderSelection()"
                >
                  <th class="text-center" style="cursor: grab" v-for="(header, index) in productSummaryHeaders" :key="index" >
                    <b style="font-size: 11px">{{ header.text }}</b>
                    <v-btn icon small class="ml-1" @click="removeHeader(header.value)" v-if="!header.required" >
                      <v-icon small>close</v-icon>
                    </v-btn>
                  </th>
                </draggable>
              </thead>
              <tbody style="height: 50vh; overflow-y: auto">
                <tr v-for="(item, i) in groupedItems" :key="i">
                  <template v-for="(header, index) in displayHeaders">
                    <td :key="index" style="font-size: 11px" v-if="header.value == 'availableQuantity'" :style="{ border: '0.4px solid rgb(95, 95, 95)' }" :rowspan=" item[header.value + '_rowspan'] ? item[header.value + '_rowspan'] : 1 " class="text-center" >
                      <v-chip v-if="item.availableQuantity > 0" color="#4CAF50" outlined label small style=" background-color: rgba(76, 175, 80, 0.25) !important; " >
                        {{ item.availableQuantity }}
                      </v-chip>
                      <v-chip v-else color="#F44336" outlined label small style=" background-color: rgba(244, 67, 54, 0.25) !important; " >
                        {{ item.availableQuantity }}
                      </v-chip>
                    </td>
                    <td :key="`${index + 2}`" style="font-size: 11px" v-else-if=" header.value != 'cartonPrice' && header.value != 'kgPrice' && item[header.value] != null " :style="{ border: '0.4px solid rgb(95, 95, 95)' }" :rowspan=" item[header.value + '_rowspan'] ? item[header.value + '_rowspan'] : 1 " class="text-center" >
                      <v-btn x-small class="mr-1" style="text-transform: none" text @click="viewDetail(item, header.value)" >
                        {{ item[header.value] }}
                      </v-btn>
                    </td>  
                    <td style="font-size: 11px" v-else-if="item[header.value] != null" :key="`${index + 22}`" class="text-center" >
                      <v-icon color="amber">lock</v-icon>
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="xeroInvoiceModal" width="1400px">
      <XeroInvoiceModal
        :invoice="invoiceItem"
        :organisations="organisations"
        :editing="invoiceItem.id && !invoiceItem.externalReference"
        @close="xeroInvoiceModal = false"
        @invoice-created="invoiceCreatedEvent"
      />
    </v-dialog>
    <v-dialog v-model="xeroCreditNoteModal" width="1400px">
      <XeroCreditNoteModal
        :creditNote="creditItem"
        :organisations="organisations"
        :invoices="orderInvoices"
        :standalone="true"
        @close="xeroCreditNoteModal = false, creditItem = {}"
        @creditNote-created="creditNoteCreatedEvent"
      />
    </v-dialog>
    <v-dialog v-model="creditNoteFromInvoiceModal" width="700px" content-class="dialogRightOnInvoice" overlay-opacity="0.1">
        <XeroCreditNoteModal
              :creditNote="creditItem"
              :organisations="organisations"
              :invoices="orderInvoices"
              :standalone="false"
              :invoice="documentInvoiceItem"
              @close="creditNoteFromInvoiceModal = false"
              @creditNote-created="creditNoteCreatedEvent"
            />
    </v-dialog>
      <v-dialog v-model="filteredPalletFinancialModal" width="1500px" persistent>
        <v-card :key="stockRefreshKey">
          <v-toolbar flat>
            <v-btn v-if="!salesItem.id" icon @click=" (filteredPalletFinancialModal = false), (filteredPallets = []), (assignFromStockModal = true) " >
              <v-icon>arrow_back</v-icon>
            </v-btn>
            <v-toolbar-title>Manage Stock Sales Item</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-chip v-if="salesItem.unitProfit && salesItem.unitProfit > 0" small color="#4CAF50" outlined style="background-color: rgba(76, 175, 80, 0.25) !important" >
              {{ getSymbol(orderFinancialSetting.baseCurrency) }}
              {{ formatNumber(salesItem.unitProfit) }} per Unit
            </v-chip>
            <v-chip v-else-if="salesItem.unitProfit" small color="#F44336" outlined style="background-color: rgba(244, 67, 54, 0.25) !important" >
              {{ getSymbol(orderFinancialSetting.baseCurrency) }}
              {{ formatNumber(salesItem.unitProfit) }} per Unit
            </v-chip>
            <v-chip small class="ml-1">
              <v-icon v-if="salesItem.unitQuantity == salesItem.allocatedQuantity" small left color="success" >
                check_circle_outline
              </v-icon>
              <v-icon v-else small left color="orange"> warning </v-icon>
              {{ salesItem.allocatedQuantity }} /
              {{ salesItem.unitQuantity }} Allocated
            </v-chip>
            <v-btn @click="saveSalesItem" :loading="savingSalesItem" icon color="primary" :disabled=" (!salesItem.isDiscarded && (!salesItem.unitPrice || !salesItem.baseAmount || !salesItem.date)) || salesItem.unitQuantity != salesItem.allocatedQuantity " >
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn icon color="red" v-if="salesItem.id" @click="confirmDeleteSale = true" >
              <v-icon>delete</v-icon>
            </v-btn>
            <v-btn text @click="filteredPalletFinancialModal = false"> X </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row align="baseline">
              <v-col cols="12" sm="4">
                <v-card flat>
                  <v-toolbar flat dense>
                    <v-toolbar-title>Detail</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <v-text-field label="Reference" class="my-1" v-model="salesItem.reference" outlined dense hide-details ></v-text-field>
                        <v-select v-if=" orderFinancialSetting.type == 'MGP' || orderFinancialSetting.type == 'FULL_CONSIGNMENT' "
                          hide-details v-model="salesItem.type" class="my-1 py-1" label="Type" dense outlined item-text="name" item-value="value"
                          :items="[ { name: 'Fixed Price', value: 'FIXED' }, { name: 'Minimum Guaranteed Price', value: 'MGP' }, { name: 'Market Sales Price', value: 'MARKET_RATE' }, ]"
                        ></v-select>
                        <v-col cols="12" class="my-0 py-0">
                          <v-row>
                            <v-col cols="12" sm="6" class="ml-0 pl-0">
                              <v-autocomplete :items="orderDocuments" class="my-1" label="Document" outlined dense hide-details item-text="name" item-value="id" v-model="salesItem.shipmentDocumentId" >
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto" >
                                <template v-slot:activator="{ on }">
                                  <v-list-item v-on="on" class="text-left" style="height: 40px" >
                                    <v-list-item-icon>
                                      <v-icon color="primary">event</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title style="font-size: 13px">
                                        {{ formatDate(salesItem.date) }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle style="font-size: 13px" >
                                        Date
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                                <v-date-picker v-model="salesItem.date" no-title scrollable >
                                </v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" class="my-0 py-0 mx-0 px-0">
                          <v-btn v-if="!addNote" @click="addNote = true" small text rounded >
                            {{ !salesItem.note && salesItem.note !== null ? "Add" : "View" }} note
                            <v-icon> {{ !salesItem.note && salesItem.note !== null ? "add_circle_outline" : "visibility" }}</v-icon ></v-btn >  
                          <v-textarea v-if="addNote" v-model="salesItem.note" rows="2" dense outlined class="my-0 py-0" label="Note" >
                            <template v-slot:append>
                              <v-icon @click="addNote = false">close</v-icon>
                            </template>
                          </v-textarea>
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="4">
                <v-card flat>
                  <v-toolbar flat dense>
                    <v-toolbar-title>Sales</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-switch dense v-model="salesItem.isDiscarded" label="Discarded" color="red" hide-details ></v-switch>
                  </v-toolbar>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="my-0 py-0">
                        <v-row>
                          <v-col cols="12" sm="4" class="mr-0 pr-0">
                            <v-text-field :label="`Units`" outlined dense class="my-1" type="number" v-model="salesItem.unitQuantity" hide-details @change="updateUnitInput()" ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="5" class="ml-0 pl-0">
                            <v-text-field v-if=" !salesItem.isDiscarded && salesItem.currency != salesItem.baseCurrency " label="Applied Exchange Rate" outlined hide-details dense class="my-1" :key="exKey" v-model="inverseExchangeRate" >
                              <template v-slot:append>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" @click="applyCurrentRate" > arrow_back</v-icon >
                                  </template>
                                  <span>Apply current rate</span>
                                </v-tooltip>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="3" class="ml-0 pl-0">
                            <v-text-field v-if=" !salesItem.isDiscarded && salesItem.currency != salesItem.baseCurrency " disabled label="Current Rate" outlined hide-details dense class="my-1" :loading="loadingCurrency" v-model="currentExchangeRate" />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" class="my-0 py-0">
                        <v-row>
                          <v-col cols="12" sm="4" class="mr-0 pr-0">
                            <v-autocomplete v-if="!salesItem.isDiscarded" label="Currency" outlined dense class="my-1" hide-details :items="currencies" v-model="salesItem.currency" ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="8" class="ml-0 pl-0">
                            <v-text-field v-if="!salesItem.isDiscarded" label="Unit Price*" :prefix="getSymbol(salesItem.currency)" outlined class="my-1" hide-details dense type="number" v-model="salesItem.unitPrice" ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" class="my-0 py-0">
                        <v-row v-if=" !salesItem.isDiscarded && salesItem.currency != salesItem.baseCurrency " >
                          <v-col cols="12" sm="4" class="mr-0 pr-0">
                            <v-autocomplete disabled label="Currency" outlined dense class="my-1" hide-details :items="currencies" v-model="salesItem.baseCurrency" ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="8" class="ml-0 pl-0">
                            <v-text-field label="Unit Base Price*" :prefix="getSymbol(salesItem.baseCurrency)" outlined class="my-1" hide-details dense type="number" v-model="salesItem.baseAmount" />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
  
              <v-col cols="12" sm="4">
                <v-card flat>
                  <v-toolbar flat dense>
                    <v-toolbar-title>Supplier Cost</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-switch v-if="salesItem.baseCurrency != salesItem.costCurrency" dense v-model="showSupplier" label="Show supplier" color="primary" hide-details ></v-switch>
                  </v-toolbar>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="my-0 py-0">
                        <v-row>
                          <v-col v-if="showSupplier" cols="12">
                            <v-autocomplete label="Supplier" class="my-1" :items="organisations.data" item-text="relatedOrganisation.name" item-value="relatedOrganisation.id" hide-details outlined v-model="salesItem.supplierId" clearable dense >
                              <template v-slot:selection="data">
                                <v-row align="center">
                                  <v-avatar size="32" :color=" data.item && data.item.relatedOrganisation.logo ? 'white' : 'secondary' " class="mx-2" v-if="data.item" >
                                    <v-img v-if="data.item.relatedOrganisation.logo" contain :src="data.item.relatedOrganisation.logo" ></v-img>
                                    <h3 v-else> {{ data.item.relatedOrganisation.name.charAt( 0 ) }} </h3>
                                  </v-avatar>
                                  <span v-if="data.item.relatedOrganisation">
                                    {{ data.item.relatedOrganisation.name }}
                                  </span>
                                </v-row>
                              </template>
                              <template v-slot:item="data">
                                <v-list-item-action>
                                  <v-avatar size="32" :color=" data.item.relatedOrganisation.logo ? 'white' : 'secondary' " >
                                    <v-img contain v-if="data.item.relatedOrganisation.logo" :src="data.item.relatedOrganisation.logo" ></v-img>
                                    <h3 v-else> {{ data.item.relatedOrganisation.name.charAt( 0 ) }} </h3>
                                  </v-avatar>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>{{ data.item.relatedOrganisation.name }}</v-list-item-title>
                                  <v-list-item-subtitle v-if="data.item.relatedOrganisation.alias" >{{ data.item.relatedOrganisation.alias }}</v-list-item-subtitle >
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col v-if="!showSupplier" cols="12" sm="6">
                            <v-text-field v-if=" !salesItem.isDiscarded && salesItem.costCurrency != salesItem.baseCurrency " label="Applied Exchange Rate" outlined hide-details dense class="my-1" :key="excKey" v-model="inverseCostExchangeRate" >
                              <template v-slot:append>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" @click="applyCurrentRateCost" > arrow_back</v-icon >
                                  </template>
                                  <span>Apply current rate</span>
                                </v-tooltip>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col v-if="!showSupplier" cols="12" sm="6">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-if=" !salesItem.isDiscarded && salesItem.costCurrency != salesItem.baseCurrency " disabled label="Current Rate" outlined hide-details v-bind="attrs" v-on="on" dense class="my-1" :loading="loadingCurrencyCost" v-model="currentExchangeRateCost" />
                              </template>
                              <span >{{ salesItem.baseCurrency }}/{{ salesItem.costCurrency }} (+/- 5min delay)</span >
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-col>
  
                      <v-col cols="12" class="my-0 py-0">
                        <v-row>
                          <v-col cols="12" sm="5" class="mr-0 pr-0">
                            <v-autocomplete label="Currency" outlined dense class="my-1" hide-details :items="currencies" v-model="salesItem.costCurrency" ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="7" class="ml-0 pl-0">
                            <v-text-field label="Unit Cost*" :prefix="getSymbol(salesItem.costCurrency)" outlined class="my-1" hide-details dense type="number" v-model="salesItem.unitCost" ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" class="my-0 py-0">
                        <v-row v-if="salesItem.costCurrency != salesItem.baseCurrency" >
                          <v-col cols="12" sm="5" class="mr-0 pr-0">
                            <v-autocomplete disabled label="Currency" outlined dense class="my-1" hide-details :items="currencies" v-model="salesItem.baseCurrency" ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="7" class="ml-0 pl-0">
                            <v-text-field label="Unit Base Cost*" :prefix="getSymbol(salesItem.baseCurrency)" outlined class="my-1" hide-details dense type="number" v-model="salesItem.baseCost" ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
  
              <v-col cols="12">
                <table style=" border: 0.25px solid grey; border-collapse: collapse; width: 100%; font-size: 11px; table-layout: auto;max-height: 50vh; 
                  overflow-y: auto " >
                  <thead style="border-bottom: 0.5px solid grey">
                    <tr>
                      <th style="border-right: 0.5px solid grey; width: 50px" v-if="!salesItem.id" ></th>
                      <th style="border-right: 0.5px solid grey">SSCC</th>
                      <th style="border-right: 0.5px solid grey">Seq.</th>
                      <th style="border-right: 0.5px solid grey"> Container No. </th>
                      <th style="border-right: 0.5px solid grey">Product</th>
                      <th style="border-right: 0.5px solid grey">Variety</th>
                      <th style="border-right: 0.5px solid grey">Mark</th>
                      <th style="border-right: 0.5px solid grey"> Inventory Code </th>
                      <th style="border-right: 0.5px solid grey">Grade</th>
                      <th style="border-right: 0.5px solid grey">Pack Code</th>
                      <th style="border-right: 0.5px solid grey">Count/Size</th>
                      <th style="border-right: 0.5px solid grey">PHC</th>
                      <th style="border-right: 0.5px solid grey">PUC</th>
                      <th style="border-right: 0.5px solid grey">No. Pallets</th>
                      <th style="border-right: 0.5px solid grey"> No. Cartons <v-chip x-small class="ml-1"> {{ salesItem.totalCartons }} </v-chip> </th>
                      <th style="border-right: 0.5px solid grey">Available</th>
                      <th style="border-right: 0.5px solid grey">Allocated</th>
                    </tr>
                  </thead>
                  <tbody class="text-center">
                    <tr v-for="(pallet, index) in salesItem.orderSaleStocks" :key="index" >
                      <td v-if="!salesItem.id" class="text-center">
                        <v-checkbox @change="updateSelectedTotals()" class="mb-2 ml-1 pb-0" hide-details dense v-model="filteredPallets" :value="pallet.stockId" ></v-checkbox>
                      </td>
                      <td>{{ pallet.sscc }}</td>
                      <td>{{ pallet.sequenceNo }}</td>
                      <td>{{ pallet.containerNo }}</td>
                      <td>{{ pallet.commodityCode }}</td>
                      <td>{{ pallet.varietyCode }}</td>
                      <td>{{ pallet.mark }}</td>
                      <td>{{ pallet.inventoryCode }}</td>
                      <td>{{ pallet.grade }}</td>
                      <td>{{ pallet.packCode }}</td>
                      <td>{{ pallet.countSize }}</td>
                      <td>{{ pallet.phc }}</td>
                      <td>{{ pallet.puc }}</td>
                      <td>{{ pallet.palletQuantity }}</td>
                      <td>{{ pallet.cartonQuantity }}</td>
                      <td>{{ pallet.availableQuantity }}</td>
                      <td class="text-center">
                        <v-chip small outlined style="border: none; width: 75px" class="text-center" >
                          <v-text-field style="font-size: 11px" type="number" @change="updateInputTotals()" class="text-center" :disabled=" pallet.availableQuantity == 0 ? true : !salesItem.id ? !filteredPallets.includes(pallet.stockId) : false " v-model="pallet.quantity" dense hide-details />
                        </v-chip>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <file-upload class="my-0 py-0" post-action="" :multiple="true" :drop="true" :drop-directory="true" v-model="fileList" ref="uploader" />

      <v-dialog v-model="discardReasonDialog" width="600px" persistent>
        <v-card>
          <v-toolbar flat dense>
            <v-toolbar-title>Discard Reason</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <p>Please provide a reason/note for the dicarded units:</p>
            <v-textarea v-model="salesItem.note" outlined></v-textarea>
            <v-row class="my-2" justify="center">
              <v-btn color="red" text @click="discardReasonDialog = false" >Cancel</v-btn >
              <v-btn color="success" :disabled="!salesItem.note" text @click="saveSalesItem" >Discard</v-btn >
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="invoiceModal" width="1000px" persistent>
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title> <span v-if="invoiceItem.id">Edit </span> <span v-else> Add </span> Invoice</v-toolbar-title >
            <v-spacer></v-spacer>
            <v-btn color="secondary" icon @click="saveInvoice" :loading="savingInvoice" >
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn text @click="invoiceModal = false"> X </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" class="my-0 py-0">
                  <v-text-field :label=" invoiceItem.contactId ? 'Invoice No. (Linked to Accounting)' : 'Invoice No.' " v-model="invoiceItem.invoiceNumber" :disabled="invoiceItem.contactId != null" outlined dense clearable hide-details >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="my-0 py-0">
                  <v-autocomplete label="Customer*" class="my-1" :items="organisations.data" item-text="relatedOrganisation.name" item-value="relatedOrganisation.id" hide-details outlined v-model="invoiceItem.customerId" @change="setAccountingContactId()" clearable dense >
                    <template v-slot:selection="data">
                      <v-row align="center">
                        <v-avatar size="32" :color=" data.item && data.item.relatedOrganisation.logo ? 'white' : 'secondary' " class="mx-2" v-if="data.item" >
                          <v-img v-if="data.item.relatedOrganisation.logo" contain :src="data.item.relatedOrganisation.logo" ></v-img>
                          <h3 v-else> {{ data.item.relatedOrganisation.name.charAt(0) }} </h3>
                        </v-avatar>
                        <span v-if="data.item.relatedOrganisation"> {{ data.item.relatedOrganisation.name }} </span>
                      </v-row>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-action>
                        <v-avatar size="32" :color=" data.item.relatedOrganisation.logo ? 'white' : 'secondary' " >
                          <v-img contain v-if="data.item.relatedOrganisation.logo" :src="data.item.relatedOrganisation.logo" ></v-img>
                          <h3 v-else> {{ data.item.relatedOrganisation.name.charAt(0) }} </h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.relatedOrganisation.name }}</v-list-item-title>
                        <v-list-item-subtitle v-if="data.item.relatedOrganisation.alias" >{{ data.item.relatedOrganisation.alias }}</v-list-item-subtitle >
                      </v-list-item-content>
                    </template>
                    <template v-slot:append>
                      <v-tooltip top v-if="invoiceItem.customerId && invoiceItem.contactId" >
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" color="success" >check_circle_outline</v-icon >
                        </template>
                        <span>Contact linked to accounting</span>
                      </v-tooltip>
                      <v-tooltip top v-else-if=" invoiceItem.customerId && !invoiceItem.contactId " >
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" color="red">error</v-icon>
                        </template>
                        <span>Contact not linked to accounting</span>
                      </v-tooltip>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" class="my-0 py-0">
                  <v-text-field label="Reference" v-model="invoiceItem.reference" outlined dense clearable hide-details />
                </v-col>
                <v-col cols="12" sm="3" class="my-0 py-0">
                  <v-text-field label="VAT Percentage" outlined class="my-1" hide-details dense type="number" v-model="invoiceItem.vatPercentage" suffix="%" />
                </v-col>
                <v-col cols="12" sm="6" class="my-0 py-0">
                  <v-row>
                    <v-col cols="12" sm="4" class="mr-0 pr-0">
                      <v-autocomplete label="Currency" outlined dense class="my-1" hide-details :items="currencies" v-model="invoiceItem.currencyCode" />
                    </v-col>
                    <v-col cols="12" sm="8" class="ml-0 pl-0">
                      <v-text-field label="Total (excl VAT)*" :prefix="getSymbol(invoiceItem.currencyCode)" outlined class="my-1" hide-details dense type="number" v-model="invoiceItem.totalExclVAT" />
                    </v-col>
                  </v-row>
                </v-col>
  
                <v-col cols="12" sm="6" class="my-0 py-0">
                  <v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto" >
                    <template v-slot:activator="{ on }">
                      <v-list-item v-on="on" class="text-left" style="height: 40px" >
                        <v-list-item-icon>
                          <v-icon color="primary">event</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 13px">
                            {{ formatDate(invoiceItem.date) }}
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 13px">
                            Date
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <v-date-picker v-model="invoiceItem.date" no-title scrollable>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" class="my-0 py-0">
                  <v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto" >
                    <template v-slot:activator="{ on }">
                      <v-list-item v-on="on" class="text-left" style="height: 40px" >
                        <v-list-item-icon>
                          <v-icon color="primary">event</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 13px">
                            {{ formatDate(invoiceItem.dueDate) }}
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 13px">
                            Due Date
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <v-date-picker v-model="invoiceItem.dueDate" no-title scrollable >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="my-0 py-0">
                  <v-list subheader dense>
                    <v-divider></v-divider>
                    <v-subheader style="font-size: 14px">Payments</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-col cols="12" sm="12" class="my-0 py-0">
                          <v-row>
                            <v-col cols="12" sm="3" class="mx-0 px-0">
                              <v-text-field label="Reference" outlined dense class="my-1" hide-details v-model="payment.reference" />
                            </v-col>
                            <v-col cols="12" sm="3" class="mx-0 px-0">
                              <v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto" >
                                <template v-slot:activator="{ on }">
                                  <v-list-item v-on="on" class="text-left mb-0 pb-0" style="height: 40px" >
                                    <v-list-item-icon>
                                      <v-icon color="primary">event</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title style="font-size: 13px">
                                        {{ formatDate(payment.date) }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle style="font-size: 13px" >
                                        Date
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                                <v-date-picker v-model="payment.date" no-title scrollable />
                              </v-menu>
                            </v-col>
                            <v-col cols="12" sm="3" class="mx-0 px-0">
                              <v-autocomplete label="Currency" outlined dense class="my-1" hide-details :items="currencies" v-model="payment.currencyCode" ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="3" class="ml-0 pl-0">
                              <v-text-field label="Amount*" :prefix="getSymbol(payment.currencyCode)" outlined class="my-1" hide-details dense type="number" v-model="payment.amount" ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon color="primary" @click="addPayment" :loading="savingPayment" :disabled="!payment.amount || !payment.currencyCode" >
                          <v-icon>add</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item style="max-width: 400px" v-for="(payment, index) in invoiceItem.payments" :key="index" >
                      <v-list-item-action>
                        <v-btn icon color="red" @click="deletePayment(payment, index)" :loading="deletingPayment" >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="payment.reference">
                          {{ payment.reference }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ formatDate(payment.date) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip small>
                          {{ getSymbol(payment.currencyCode) }}
                          {{ formatNumber(payment.amount) }}
                        </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="xeroInvoiceModal" width="1400px">
        <XeroInvoiceModal :invoice="invoiceItem" :organisations="organisations" @close="xeroInvoiceModal = false" @invoice-created="invoiceCreatedEvent" />
      </v-dialog>

      <v-dialog v-model="packingListModal" width="400px">
        <v-card>
          <v-toolbar flat dense>
            <v-toolbar-title >Packing List ({{ selectedContainers.length }})</v-toolbar-title >
            <v-spacer></v-spacer>
            <v-btn icon @click="createPackingLists()" color="primary">
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn icon @click="packingListModal = false">
              <v-icon>close</v-icon>
            </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-list dense>
                <v-list-item v-for="(item, index) in containers" :key="index"> <v-list-item-action>
                    <v-checkbox v-model="item.selected" @change="updateSelectedContainers()" ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.containerNo }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.ctoNo }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-container>
                      <v-row justify="center">
                        <v-chip small :color="orderItemStatusColor(item.stockStatus)" >{{item.stockStatus}} </v-chip>
                        </v-row>
                      </v-container>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
          </v-card>
      </v-dialog>

      <v-dialog v-model="accountSalesItemExchangeRate" width="25%">
          <v-card>
            <v-toolbar flat>
              <v-toolbar-title>Set exchange rate 
              </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn text @click="saveSupplierRate"><v-icon>save</v-icon></v-btn>
            <v-btn text @click="closeSupplierRate">X</v-btn>
            </v-toolbar>
            <v-card-title v-if="currentSupplier.name">
              <v-avatar left size="24" :color="currentSupplier.logo ? 'white' : 'secondary'" >
                <v-img v-if="currentSupplier.logo" :src="currentSupplier.logo" contain ></v-img>
                <h3 v-else>{{ currentSupplier.name.charAt(0) }}</h3>
              </v-avatar>
              {{ currentSupplier.alias ? currentSupplier.alias : currentSupplier.name }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" md="6" sm="3">
                      <v-text-field disabled label="Currency" outlined dense hide-details v-model="orderFinancialSetting.baseCurrency" ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="3">
                      <v-autocomplete label="Currency" outlined dense hide-details :items="currencies" v-model="currentSupplier.currency" ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" class="my-0 pt-0">
                      <v-text-field hide-details dense :loading="loadingCurrency" outlined disabled class="my-1 py-0" label="Current rate" v-model="currentExchangeRate">
                      <template v-slot:append>
                        <v-icon @click="applyCurrentRateForSupplier">arrow_forward</v-icon>
                      </template>
                    </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="my-0 pt-0">
                      <v-text-field hide-details dense outlined class="my-1 py-0" label="Applied Exchange rate" v-model="supplierExchangeRate"></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
      </v-dialog>
      <v-dialog v-model="xeroEditInvoiceModal" :width="generalInvoiceDocumentView ? '58%':'1400px'"  :content-class="generalInvoiceDocumentView ? 'dialogRight':''">
      <XeroInvoiceModal
        :invoice="invoiceToEdit"
        :organisations="organisations"
        :editing="true"
        @close="xeroEditInvoiceModal = false"
        @invoice-updated="invoiceUpdatedEvent"
      />
    </v-dialog>
      <v-dialog v-model="xeroEditCreditNoteModal" :width="generalCreditNoteDocumentView ? '38%':'1400px'"  :content-class="generalCreditNoteDocumentView ? 'dialogRight':''">
      <XeroCreditNoteModal
        :creditNote="creditNoteToEdit"
        :organisations="organisations"
        :invoice="orderInvoices.find((x) =>x.id === creditNoteToEdit.invoiceId)"
        :editing="true"
        @close="xeroEditCreditNoteModal = false"
        @creditNote-updated="creditNoteUpdatedEvent"
      />
    </v-dialog>
    </div>
</template>

  <script>
  import axios from "axios";
  import CostingSheet from "../../Finance/components/CostingSheet.vue";
  import * as CurrencyCodes from "currency-codes";
  import dateFormat from "dateformat";
  import draggable from "vuedraggable";
  import getSymbolFromCurrency from "currency-symbol-map";
  import FileUpload from "vue-upload-component";
  import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
  import XeroInvoiceModal from "../../Finance/components/XeroInvoiceModal.vue";
  import XeroCreditNoteModal from "../../Finance/components/XeroCreditNoteModal.vue";
  export default {
    props: {
      order: {
        type: Object,
        required: true,
      },
      containers: {
        type: Array,
        required: true,
      },
      organisations: {
        type: Object,
        required: true,
      },
      loadingOrganisations: {
        type: Boolean,
        required: true,
      },
      sideDrawer: {
        type: Boolean,
        required: true,
      },
    },
    components: {
      CostingSheet,
      draggable,
      FileUpload,
      VuePdfEmbed,
      XeroInvoiceModal,
      XeroCreditNoteModal
    },
    data: () => ({
      salesModalPosition: { X: 420, Y: 14},
      accountSalesItemExchangeRate: false,
      addNote: false,
      scaffolding: false,
      accountSales: [],
      creatingAccountSale: false,
      assignFromStockModal: false,
      billingItems: [],
      categories: ["Origin", "Freight", "Destination"],
      subcategories: [
        "Transport",
        "Documentation",
        "Statutory",
        "Storage",
        "Insurance",
        "Terminal Handling Charges",
        "Commissions",
        "Miscellaneous",
      ],
      creatingPackingList: false,
      currentExchangeRate: 0,
      currentExchangeRateCost: 0,
      decimalPrimary: 4,
      exKey: 0,
      excKey: 0,
      loadingCurrency: false,
      loadingOrderDocuments: false,
      loadingCurrencyCost: false,
      columnDrag: false,
      confirmDeleteSale: false,
      containerKey: 100,
      currencies: CurrencyCodes.codes(),
      currencyModal: false,
      deletingPayment: false,
      documentCreditNoteItem: {},
      deletingSalesItem: false,
      discardReasonDialog: false,
      documentKey: 100,
      documentItem: {},
      documentViewModal: false,
      expanded: [],
      financeChargeItems: [],
      fileList: [],
      filterBookingContainerId: null,
      filteredPalletFinancialModal: false,
      documentInvoiceItem: {},
      filteredPallets: [],
      groupBy: [
        "containerNo",
        "commodityCode",
        "varietyCode",
        "grade",
        "packCode",
        "mark",
        "inventoryCode",
        "countSize",
      ],
      groupedItems: [],
      integrationType: null,
      invoiceModal: false,
      invoiceItem: {},
      generalCreditNoteDocumentView: false,
      lastIndex: 0,
      loading: false,
      creditNoteModal: false,
      creditItem: {},
      loadingBilling: false,
      orderCreditNotes: [],
      loadingSales: false,
      orderDocuments: [],
      orderInvoices: [],
      xeroCreditNoteModal: false,
      deleteCreditNoteModal: false,
      generalInvoiceDocumentView: false,
      filteredCreditNotes: [],
      deleteInvoiceModal: false,
      creditNoteToDeleteId: null,
      deletingCreditNote: false,
      orderFinancialSetting: {},
      creditNoteFromInvoiceModal: false,
      invoiceCredit: null,
      xeroEditInvoiceModal: false,
      xeroEditCreditNoteModal: false,
      invoiceToEdit: {},
      creditNoteToEdit: {},
      filCreditKey: 0,
      invoice: null,
      orderItemKey: 200,
      orderItemStatus: [
        { name: "In Progress", icon: "pending", color: "blue" },
        { name: "Allocated", icon: "fact_check", color: "cyan" },
        { name: "Confirmed", icon: "verified", color: "teal" },
        { name: "Instruction Sent", icon: "email", color: "indigo" },
        { name: "Instruction Cancelled", icon: "warning", color: "orange" },
        { name: "Stuffed", icon: "check_circle_outline", color: "lime" },
        { name: "Stuff Discrepancy", icon: "error", color: "red" },
        { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
        { name: "Cancelled", icon: "cancel", color: "red" },
      ],
      packingListModal: false,
      payment: { date: new Date().toISOString().substr(0, 10), },
      productSummaryHeaders: [],
      productKey: 500,
      allHeaders: [
        {
          text: "Container No.",
          value: "containerNo",
          align: "center",
          group: true,
          selectable: true,
          show: true,
        },
        {
          text: "Order Item",
          value: "ctoNo",
          align: "center",
          group: true,
          selectable: true,
          show: false,
        },
        {
          text: "SSCC",
          value: "sscc",
          align: "center",
          group: true,
          selectable: true,
          show: false,
        },
        {
          text: "Product",
          value: "commodityCode",
          align: "center",
          group: true,
          selectable: true,
          show: true,
        },
        {
          text: "Variety",
          value: "varietyCode",
          align: "center",
          group: true,
          selectable: true,
          show: true,
        },
        {
          text: "Mark",
          value: "mark",
          align: "center",
          group: true,
          selectable: true,
          show: true,
        },
        {
          text: "Inventory Code",
          value: "inventoryCode",
          align: "center",
          group: true,
          selectable: true,
          show: true,
        },
        {
          text: "Grade/Class",
          value: "grade",
          align: "center",
          group: true,
          selectable: true,
          show: true,
        },
        {
          text: "Pack Code",
          value: "packCode",
          align: "center",
          group: true,
          selectable: true,
          show: true,
        },
        {
          text: "Count/Size",
          value: "countSize",
          align: "center",
          group: true,
          selectable: true,
          show: true,
        },
        {
          text: "PHC",
          value: "phc",
          align: "center",
          group: true,
          selectable: true,
          show: false,
        },
        {
          text: "PUC",
          value: "puc",
          align: "center",
          group: true,
          selectable: true,
          show: false,
        },
        {
          text: "No. Pallets",
          value: "palletQuantity",
          align: "center",
          isQuantity: true,
          required: true,
        },
        {
          text: "No. Cartons",
          value: "cartonQuantity",
          align: "center",
          isQuantity: true,
          required: true,
        },
        {
          text: "Available",
          value: "availableQuantity",
          align: "center",
          isQuantity: true,
          required: true,
        },
      ],
      savingBillingItem: false,
      savingDocument: false,
      savingPayment: false,
      savingSalesItem: false,
      search: null,
      salesModal: false,
      salesItem: {},
      salesItems: [],
      savingInvoice: false,
      selectedContainers: [],
      stockRefreshKey: 600,
      xeroInvoiceModal: false,
      showSupplier: true,
      inverseExchangeRate: 1,
      inverseCostExchangeRate: 1,
      salesSuppliers: [],
      currentSupplier: {},
      related: [],
      supplierExchangeRate: 1,
      salesSuppliersSettings: [],
      needsUpdating: false,
      accUp: 0,
      ufsc: 0,
      fss: 0,
      loadingDiscrepancy: false,
      discrepancies: [],
      discrepenciesDetail: [],
      ddc: 0,
      discrepanciesChecked: false,
    }),
    watch: {
      fileList: {
        immediate: true,
        handler(val) {
          if (val.length > 0) {
            this.submitFiles();
          }
        },
      },
      "generalInvoiceDocumentView": {
      immediate: true,
      handler(val) {
        if(!this.generalInvoiceDocumentView) {
          this.invoiceCredit = {
            totalExclVAT: 0,
            totalInclVAT: 0,
            currencyCode: this.orderFinancialSetting.baseCurrency,
          }
        }
      }
    },
      "currentSupplier.currency": {
        immediate: true,
        handler(val) {
          if(val) {
            this.getCurrentRate(this.orderFinancialSetting.baseCurrency, this.currentSupplier.currency)
          }
        }
      },
      "inverseExchangeRate":{
        immediate: true,
        handler: function (val) {
          if (val) {
            if (this.inverseExchangeRate !== 0) {
              this.salesItem.baseAmount = parseFloat(
                (this.salesItem.unitPrice / this.inverseExchangeRate).toFixed(2)
              );
            }
          }
        },
      },
      inverseCostExchangeRate: {
        immediate: true,
        handler: function (val) {
          if (val) {
            if (this.inverseExchangeRate !== 0) {
              this.salesItem.baseCost = parseFloat(
                (this.salesItem.unitCost / this.inverseCostExchangeRate).toFixed(
                  2
                )
              );
            }
          }
        },
      },
      "order.id": {
        handler: function (val) {
          if (val) {
            this.loadFinancialSettings(val);
            this.loadBillingItems(val);
            this.loadSalesItems(val);
            this.getOrderDocuments(val);
            this.getAccountSales(val);
            this.getOrderInvoices(val);
            this.getOrderCreditNotes(val);
          }
        },
        immediate: true,
      },
      "currentSupplier.exchangeRate": {
        immediate: true,
        handler (val) {
          if(val) {
            if(this.currentSupplier.exchangeRate && this.supplierExchangeRate === 1) {
              this.supplierExchangeRate = parseFloat((1/this.currentSupplier.exchangeRate).toFixed(4))
            }
          }
        }
      },
      "supplierExchangeRate": {
        immediate: true,
        handler(val) {
          if(val) {
              this.currentSupplier.exchangeRate = parseFloat((1/this.supplierExchangeRate))
          }
        }
      },
      "salesItem.exchangeRate": {
        immediate: true,
        handler: function (val) {
          if (val) {
            if(!this.salesItem.id) {
              this.inverseExchangeRate = 1
            } else {
              let inverse = parseFloat(
                (1 / this.salesItem.exchangeRate).toFixed(this.decimalPrimary)
              );
              if (this.inverseExchangeRate !== inverse)
                this.inverseExchangeRate = inverse;
            }
          }
        },
      },
      "salesItem.costExchangeRate": {
        immediate: true,
        handler: function (val) {
          if (val) {
            if (!this.salesItem.id) {
              this.inverseCostExchangeRate = 1;
            } else {
              let inverse = parseFloat(
                (1 / this.salesItem.costExchangeRate).toFixed(this.decimalPrimary)
              );
              if (this.inverseCostExchangeRate !== inverse)
                this.inverseCostExchangeRate = inverse;
            }
          }
        },
      },
      "salesItem.currency": {
        immediate: true,
        handler: function(val) {
          if(val) {
            if(this.inverseExchangeRate === 1 || this.inverseExchangeRate === undefined && this.salesItem.exchangeRate) {
                this.inverseExchangeRate = (1 / this.salesItem.exchangeRate).toFixed(this.decimalPrimary)
              }
            if(this.salesItem.baseCurrency !== this.salesItem.currency) {
              if(!this.salesItem.id) {
                this.inverseExchangeRate = 1
              }
              this.getCurrentRate(
                this.salesItem.baseCurrency,
                this.salesItem.currency
              );
            }
          }
        },
      },
      "salesItem.unitPrice": {
        immediate: true,
        handler: function (val) {
          if (val) {
            if (this.salesItem.baseCurrency == this.salesItem.currency) {
              this.salesItem.baseAmount = this.salesItem.unitPrice;
            } else {
              this.salesItem.baseAmount = parseFloat(
                (this.salesItem.unitPrice / this.inverseExchangeRate).toFixed(2)
              );
            }
          }
        },
      },
      "salesItem.unitCost": {
        immediate: true,
        handler(val) {
          if(val){
            if(this.salesItem.costCurrency !== this.salesItem.baseCurrency) {
              this.salesItem.baseCost = Math.round((this.salesItem.unitCost / this.inverseCostExchangeRate) * 100) / 100
            }
          }
        },
      },
      "salesItem.costCurrency": {
        immediate: true,
        handler(val) {
          if (val) {
            if (this.salesItem.costCurrency !== this.salesItem.baseCurrency) {
              if ( (this.inverseCostExchangeRate === 1 || this.inverseCostExchangeRate === undefined) && this.salesItem.costExchangeRate && this.salesItem.costExchangeRate !== null ) {
                this.inverseCostExchangeRate = ( 1 / this.salesItem.costExchangeRate ).toFixed(this.decimalPrimary);
              }
              if (this.salesItem.baseCurrency !== this.salesItem.costCurrency) {
                if (!this.salesItem.id) {
                  this.inverseCostExchangeRate = 1;
                }
                this.getCurrentRateCost( this.salesItem.baseCurrency, this.salesItem.costCurrency );
              }
              this.showSupplier = false;
            } else if (
              this.salesItem.costCurrency === this.salesItem.baseCurrency
            ) {
              this.showSupplier = true;
            }
          }
        },
      },
      "billingItem.amount": {
        immediate: true,
        handler: function (val) {
          if (val) {
            if (this.billingItem.baseCurrency == this.billingItem.currency) {
              this.billingItem.baseAmount = this.billingItem.amount;
            }
          }
        },
      },
    },
    async created() {
      this.financeChargeItems = await this.$API.getOrgFinanceChargeItems();
      this.productSummaryHeaders = [
        ...this.allHeaders.filter((x) => x.show || x.required),
      ];
      await this.getActiveChildSettings()
      await this.getRelations()
    },
    computed: {
      totalInvoiced() {
      if(this.orderInvoices.length > 0) {
        let totalInclVAT = this.orderInvoices.reduce((a, b) => a + parseFloat(b.totalInclVAT), 0);
        let totalExclVAT = this.orderInvoices.reduce((a, b) => a + parseFloat(b.totalExclVAT), 0);

        // let hasDiscrepancy = this.orderInvoices.some(invoice => {
        //   if(invoice.totalExclVAT > invoice.totalInclVAT) return true
        // });
        let exclVATCount = 0
        let inclVATCount = 0
        for(const item of this.orderInvoices) {
          if(item.totalInclVAT > item.totalExclVAT) {
            exclVATCount++
            inclVATCount++
          } else {
            inclVATCount++
          }
        }
        let obj = {
          totalInclVAT,
          totalExclVAT,
        }
        return obj
      } else {
        return 0
      }
    },
    totalCredited() {
      if(this.orderCreditNotes.length > 0) {
        return this.orderCreditNotes.reduce((a,b) => a + parseFloat(b.totalInclVAT), 0)
      } else {
        return 0
      }
    },
      availableHeaders() {
        return this.allHeaders.filter(
          (x) => !this.productSummaryHeaders.map((y) => y.text).includes(x.text)
        );
      },
      availableProducts() {
        let products = [].concat.apply( [], this.containers.map((x) => x.orderCargoItems.filter((y) => y.product).map((y) => y.product) ) );
        let uniqueProducts = [...new Set(products.map((x) => x.id))];
        let result = uniqueProducts.map((x) => products.find((y) => y.id == x));
        result.sort((a, b) => a.name.localeCompare(b.name));
        return result;
      },
      displayHeaders() {
        return this.productSummaryHeaders.filter((x) => x.show || x.required);
      },
      productBreakdown() {
        return this.groupData();
      },
      filterSalesItems() {
        let key = this.fss
        let result = this.salesItems;
        if (this.filterBookingContainerId) {
          let filterStock = this.containers.find( (x) => x.id == this.filterBookingContainerId );
          filterStock = filterStock.stocks ?? [];
          filterStock = filterStock.map((x) => x.id);
          result = result.filter((x) => x.bookingContainerId == this.filterBookingContainerId || (x.orderSaleStocks.length > 0 && x.orderSaleStocks.some((y) => filterStock.includes(y.stockId))) );
        }
        for(const item of result) {
          this.addSupplier(item.supplier)
        }
        this.checkSuppliers(result)
        return result;
      },
      filterSaleCostItems() {
        let key = this.ufsc
        let result = this.salesItems.filter((x) => x.supplierId);
        if (this.filterBookingContainerId) {
          let filterStock = this.containers.find( (x) => x.id == this.filterBookingContainerId );
          filterStock = filterStock.stocks ?? [];
          filterStock = filterStock.map((x) => x.id);
          result = result.filter( (x) => x.bookingContainerId == this.filterBookingContainerId || (x.orderSaleStocks.length > 0 && x.orderSaleStocks.some((y) => filterStock.includes(y.stockId))) );
        }
        return result;
      },
      filterCostItems() {
        let result = this.billingItems;
        if (this.filterBookingContainerId) {
          result = result.filter( (x) => x.childItems && x.childItems.some( (y) => y.bookingContainerId == this.filterBookingContainerId ) );
        }
        return result;
      },
      packingLists() {
        let documents = this.containers.filter((x) => x.packingListDocument);
        return documents
      },
      salesTypes() {
        let uniqueTypes = [...new Set(this.salesItems.map((x) => x.type))];
        uniqueTypes = uniqueTypes.map((x) => ({ name: x == "FIXED" ? "Fixed Price" : x == "MGP" ? "Minimum Guaranteed Price" : "Market Sales Price", value: x, }));
        return uniqueTypes;
      },
      totalCost() {
        let items = this.billingItems.filter((x) => !x.parentBillingItemId);
        if (this.filterBookingContainerId) {
          items = this.billingItems.filter( (x) => x.bookingContainerId == this.filterBookingContainerId );
        }
        return items.map((x) => parseFloat(x.baseAmount)).reduce((a, b) => a + b, 0).toFixed(2);
      },
      totalSales() {
        let items = this.filterSalesItems.filter( (x) => x.type == "FIXED" || x.type == "MARKET_RATE" );
        return items.map((x) => parseFloat(x.baseTotal)).reduce((a, b) => a + b, 0).toFixed(2);
      },
      totalProfit() {
        return Number((this.totalSales - this.totalCost).toFixed(2));
      },
      myTotalProfit() {
        let items = this.billingItems.filter( (x) => !x.parentBillingItemId && x.supplierId == this.$store.state.currentOrg.id );
        if (this.filterBookingContainerId) {
          items = this.billingItems.filter( (x) => x.bookingContainerId == this.filterBookingContainerId && x.supplierId == this.$store.state.currentOrg.id );
        }
        return items.map((x) => parseFloat(x.baseAmount)).reduce((a, b) => a + b, 0).toFixed(2);
      },
      myUnitProfit() {
        return (this.myTotalProfit / this.totalUnits).toFixed(2);
      },
      totalUnits() {
        let containers = this.containers.map((x) => x.orderCargoItems);
        if (this.filterBookingContainerId) {
          containers = this.containers.filter((x) => x.id == this.filterBookingContainerId).map((x) => x.orderCargoItems);
        }
        let items = [].concat.apply([], containers).map((x) => x.quantity);
        return items.reduce((a, b) => a + b, 0);
      },
      unitCost() {
        let items = this.billingItems.filter((x) => !x.parentBillingItemId);
        if (this.filterBookingContainerId) { items = this.billingItems.filter( (x) => x.bookingContainerId == this.filterBookingContainerId ); }
        return (
          items.map((x) => parseFloat(x.baseAmount)).reduce((a, b) => a + b, 0) /
          this.totalUnits
        ).toFixed(2);
      },
      unitSales() {
        let items = this.filterSalesItems.filter( (x) => x.type == "FIXED" || x.type == "MARKET_RATE" );
        return (
          items.map((x) => parseFloat(x.baseTotal)).reduce((a, b) => a + b, 0) /
          this.totalUnits
        ).toFixed(2);
      },
      unitProfit() {
        return (this.unitSales - this.unitCost).toFixed(2);
      },
      issuePallets(){
        let issuePalletCount = this.discrepancies.reduce((sum, val) => {
          return sum += Number(val.issuePallets)
        }, 0)
        return issuePalletCount
      },
    },
    methods: {
      async getInvoiceCredit(id) {
      try{
        let credit = await this.$API.getInvoiceCredit(id)
        this.invoiceCredit = credit

      }catch(e) { 
        this.invoiceCredit = null
      }
    },
          addCreditNoteFromInvoice() {
            this.creditNoteFromInvoiceModal = true
            this.creditItem.frontendDate = new Date().toISOString().split("T")[0]
    },
    filterCreditNotes(invoiceId) {
      this.filteredCreditNotes = this.orderCreditNotes.filter((x) => x.invoiceId === invoiceId)
    },
    removeElementById(idToRemove, items) {
      items = items.filter(item => item.id !== idToRemove);
      return items
    },
    async deleteCreditNote(){
      this.deletingCreditNote = true
      let deletedCreditNote = await this.$API.deleteCreditNote(this.creditNoteToDeleteId) // need to be tested still
      if(deletedCreditNote) {
        this.orderCreditNotes = this.removeElementById(this.creditNoteToDeleteId, this.orderCreditNotes)
        this.deleteCreditNoteModal = false
        this.deletingCreditNote = false
      } else {
        // this.deleteCreditNoteModal = false
        this.deletingCreditNote = false
      }

    },
    doNotDeleteCreditNote() {
      this.creditNoteToDeleteId = null
      this.deleteCreditNoteModal = false
    },
    selectCreditNoteToDelete(id) {
      this.deleteCreditNoteModal = true
      this.creditNoteToDeleteId = id
    },
    async deleteInvoice(){
      this.deletingInvoice = true
      let deletedInvoice = await this.$API.deleteInvoice(this.invoiceToDeleteId) // need to be tested still
      if(deletedInvoice) {
        this.orderInvoices = this.removeElementById(this.invoiceToDeleteId, this.orderInvoices)
        this.deleteInvoiceModal = false
        this.deletingInvoice = false
      } else {
        // this.deleteCreditNoteModal = false
        this.deletingInvoice = false
      }

    },
    async editInvoiceDoc(invoiceId) {
      let invoice = this.orderInvoices.find((x) => x.id === invoiceId)
      this.invoiceToEdit = invoice
      if (!this.integrationType) {
          this.integrationType = await this.$API.getAccountingIntegration();
        }
      if (this.integrationType == "ZA_Xero") {
          this.xeroEditInvoiceModal = true;
        } else {
          // this.editInvoiceModal = true
          this.invoiceItem = invoice
          // this.$message.error("Unavailable");
          this.invoiceModal = true;
        }
    },
    doNotDeleteInvoice() {
      this.invoiceToDeleteId = null
      this.deleteInvoiceModal = false
    },
    selectInvoiceToDelete(id) {
      this.deleteInvoiceModal = true 
      this.invoiceToDeleteId = id
    },
      containsDiscrepancy(item) {
      let num = this.ddc
      // console.log('item.orderCargoItems')
      // console.log(item)
      // console.log(item.orderSaleStocks)
      if(item.orderSaleStocks && this.discrepenciesDetail.length && this.issuePallets > 0) {
        for(const stock of item.orderSaleStocks) {
          let discrepencyStock = false
          for(const item of this.discrepenciesDetail) {
            discrepencyStock = item.find((x) => x.id === stock.stockId)
            if(discrepencyStock) {
            return true
          }
          }

          if(discrepencyStock) {
            return true
          }
        }
        return false
      } else {
        return false
      }
    },
    async getDiscrepancies() {
      this.loadingDiscrepancy = true;
      this.discrepancies = await this.$API.getStockDiscrepancyOrder(this.order.id);
      if(this.discrepancies.length !== 0) {
        for(const disrepency of this.discrepancies){
          let detail = await this.$API.getStockDiscrepancyDetail(disrepency.id);
          this.discrepenciesDetail.push(detail)
          this.ddc++
        }
      }
      this.discrepanciesChecked = true
      this.loadingDiscrepancy = false;
    },
      accountSaleNeedsUpdatingRates(){
          if(this.salesSuppliers.length !== 0) {
            for(const item of this.salesSuppliers) {
              let accountSaleItem = this.accountSales.find((x)=>x.supplier.id === item.organisationId)
              if(accountSaleItem) {
                if(item.exchangeRate && accountSaleItem.exchangeRate) {
                  if(Number(accountSaleItem.exchangeRate).toFixed(4) != Number(item.exchangeRate).toFixed(4)) {
                    return true
                  }
                } else if(item) {
                  if(item.exchangeRate && !accountSaleItem.exchangeRate) {
                    return true
                  }
                }
              } else {
                return true
              }
            }
            return false
          }
          return false
      },
      accountSaleNeedsUpdatingSuppliers(){
        try{
          if(this.salesSuppliers.length && this.accountSales.length) {
              if(this.salesSuppliers.length !== this.accountSales.length) {
                return true
              } else {
                for(const item of this.accountSales) {
                  let total = 0
                  for(const salesItem of this.filterSaleCostItems) {
                    if(salesItem.supplierId === item.supplier.id) {
                      total += Number(salesItem.unitQuantity)
                    }
                  }
                  if(item.quantity !== total) {
                    return true
                  }
                }
              }
              return false
            }
        }catch(e) {
          console.error(e)
          return true
        }
      },
      async getActiveChildSettings() {
        let res = await this.$API.getActiveChildSettings(this.orderFinancialSetting.id)
        this.salesSuppliersSettings = res
        for(const item of res) {
          let { organisation , ...rest } = item
          this.salesSuppliers.push({...rest,...organisation})
        }
      },
      canGenerateAccountSale() {
       for(const supplier of this.salesSuppliers) {
        if(supplier.currency !== this.orderFinancialSetting.baseCurrency && !supplier.exchangeRate) {
          return true
        }
       } 
       return false
      },
      async getRelations() {
        if ( localStorage["relatedOrganisations_" + this.$store.state.currentOrg.id] ) {
          this.related = JSON.parse( localStorage[ "relatedOrganisations_" + this.$store.state.currentOrg.id ] );
        }
        this.related = await this.$API.getRelationBasic({
        });
        let findCurrentOrg = this.related.data.find( (x) => x.relatedOrganisationId == this.$store.state.currentOrg.id );
        if (!findCurrentOrg) {
          this.related.data.unshift({relatedOrganisationId: this.$store.state.currentOrg.id, 
            relatedOrganisation: this.$store.state.currentOrg
          });
        }
        localStorage.setItem( "relatedOrganisations_" + this.$store.state.currentOrg.id, JSON.stringify(this.related) );
      },
      viewSupplierRate(supplier, index) {
        this.currentSupplier = {...supplier, index: index}
        if(!this.currentSupplier.exchangeRate) {
          this.currentSupplier.exchangeRate = parseFloat((1/this.supplierExchangeRate).toFixed(4))
        }
        this.accountSalesItemExchangeRate = true
      },
      closeSupplierRate() {
        this.currentSupplier = {}
        this.accountSalesItemExchangeRate = false
      },
      async saveSupplierRate() {
        let { exchangeRate, currency, ...rest} = this.currentSupplier
        this.salesSuppliers[this.currentSupplier.index] = this.currentSupplier
        if(this.currentSupplier.id) {
          let res = await this.$API.updateChildAccountSaleSetting(this.currentSupplier)
          this.salesSuppliers[this.currentSupplier.index] = {...res, ...rest}
        } else {
          let res = await this.$API.createChildAccountSaleSetting(this.currentSupplier)
          this.salesSuppliers[this.currentSupplier.index] = {...res, ...rest}
        }
        this.currentSupplier = {}
        this.accountSalesItemExchangeRate = false
      },
      addSupplier(supplier) {
        if(supplier && this.related.data) {
          if(!this.salesSuppliers.find((el) => el.organisationId === supplier.id)) {
            supplier.exchangeRate = 1
            let relations = this.related.data.find((x) => x.relatedOrganisation.id === supplier.id)
            let {id, ...rest} = supplier
            let supplierObj = {...rest, organisationId: id, currency: relations.currency, exchangeRate: null, parentSettingId: this.orderFinancialSetting.id}
            this.salesSuppliers.push(supplierObj)
          }
        }
      },
      async getSupplierSetting(supplier) {
        try {
          let res = await this.$API.getChildSetting({parentSettingId: supplier.parentSettingId, organisationId: supplier.organisationId});
          if (res.id) {
            let index = this.salesSuppliers.findIndex((el) => el.organisationId === res.organisationId);
            if (index !== -1) {
              this.salesSuppliers[index] = {...supplier, ...res};
            }
          }
        } catch (error) {
          console.error('Error getting supplier setting:', error);
        }
      },
      checkSuppliers(arr) {
        const matchedItems = [];
        const unusedItemsToRemove = [];
        this.salesSuppliers.forEach(item => {
          if (arr.some(el => el.supplier.id === item.organisationId)) {
            matchedItems.push(item);
          } else {
            if(item.id) {
              unusedItemsToRemove.push(item);
            }
          }
        });
        this.salesSuppliers = matchedItems;
        if(unusedItemsToRemove.length !== 0) {
          this.removeUnusedItems(unusedItemsToRemove)
        }
      },
      async removeUnusedItems(unusedItems) {
          let res = await this.$API.deleteChildSettings(unusedItems)
      },
      applicableAccountSale(supplierId, sales) {
        let arr = []
        for(const item of sales) {
          if(item.supplier.id === supplierId) {
            arr.push(item)
          }
        }
        return arr
      },
      logItem(item) {
        console.log(item)
      },
      updateBillingItems(billingItems) {
        this.billingItems = billingItems;
      },
      addFiles() {
        this.$refs.uploader.$el.children[0].click();
      },
      async addInvoice() {
        if (!this.integrationType) {
          this.integrationType = await this.$API.getAccountingIntegration();
        }
        this.invoiceItem = {
          orderId: this.order.id,
          currencyCode: this.orderFinancialSetting.baseCurrency,
          status: "DRAFT",
          type: "INVOICE",
          totalExclVAT: 0,
          totalInclVAT: 0,
          amountDue: 0,
          vatPercentage: 15,
          date: new Date().toISOString().split("T")[0],
          dueDate: new Date().toISOString().split("T")[0],
          customerId: this.order.orderCustomerId,
          payments: [],
          invoiceLineItems: [],
        };
        this.payment = {
          date: new Date().toISOString().split("T")[0],
          currencyCode: this.orderFinancialSetting.baseCurrency,
        };
        this.setAccountingContactId();
        if (this.integrationType == "ZA_Xero") {
          this.xeroInvoiceModal = true;
        } else {
          this.invoiceModal = true;
        }
      },
      async editInvoice(invoice) {
      if (invoice.externalReference) {
        this.documentItem = {
          id: invoice.id,
          title: "Invoice: " + invoice.invoiceNumber,
          status: invoice.status,
          url: invoice.invoiceDocument,
          invoiceNumber: invoice.invoiceNumber,
        };
        this.filterCreditNotes(invoice.id)
        this.creditItem = {
        frontendDate: new Date().toISOString().split("T")[0],
        orderId: this.order.id,
        invoiceId: invoice.id,
        invoiceNumber: invoice.invoiceNumber,
        currencyCode: this.orderFinancialSetting.baseCurrency,
        status: "DRAFT",
        type: "INVOICE",
        totalExclVAT: 0,
        totalInclVAT: 0,
        amountDue: 0,
        vatPercentage: 15,
        date: new Date().toISOString().split("T")[0],
        dueDate: new Date().toISOString().split("T")[0],
        customerId: this.order.orderCustomerId,
        payments: [],
        creditNoteLineItems: [],
      };
        this.documentInvoiceItem = this.documentItem
        this.generalInvoiceDocumentView = true;
      } else {
        if (!this.integrationType) {
          this.integrationType = await this.$API.getAccountingIntegration();
        }
        this.invoiceItem = invoice;
        this.payment = {
          date: new Date().toISOString().split("T")[0],
          currencyCode: this.orderFinancialSetting.baseCurrency,
        };
        if (this.integrationType == "ZA_Xero") {
          this.xeroInvoiceModal = true;
        } else {
          this.invoiceModal = true;
        }
      }
    },
    async addCreditNote() {
      if (!this.integrationType) {
        this.integrationType = await this.$API.getAccountingIntegration();
      }
      this.creditItem = {
        frontendDate: new Date().toISOString().split("T")[0],
        orderId: this.order.id,
        currencyCode: this.orderFinancialSetting.baseCurrency,
        status: "DRAFT",
        type: "CREDITNOTE",
        totalExclVAT: 0,
        totalInclVAT: 0,
        amountDue: 0,
        vatPercentage: 15,
        date: new Date().toISOString().split("T")[0],
        dueDate: new Date().toISOString().split("T")[0],
        customerId: this.order.orderCustomerId,
        payments: [],
        creditNoteLineItems: [],
      };
      this.payment = {
        date: new Date().toISOString().split("T")[0],
        currencyCode: this.orderFinancialSetting.baseCurrency,
      };
      this.setAccountingContactId();
      if (this.integrationType == "ZA_Xero") {
        this.xeroCreditNoteModal = true;
      } else {
        this.creditNoteModal = true;
      }
    },
    async editCreditNoteDoc(creditNoteId) {
      let creditNote = this.orderCreditNotes.find((x) => x.id === creditNoteId)
      let invoice = this.orderInvoices.find((x) => x.id === creditNote.invoiceId)
      creditNote.invoiceNumber = invoice.invoiceNumber
      this.creditNoteToEdit = creditNote

        if (!this.integrationType) {
          this.integrationType = await this.$API.getAccountingIntegration();
        }
        if (this.integrationType == "ZA_Xero") {
          this.xeroEditCreditNoteModal = true;
        } else {
          this.creditItem = creditNote
          this.creditNoteModal = true;
        }
    },
    async editCreditNote(creditNote) {
      if (creditNote.externalReference) {
        this.documentItem = {
          id: creditNote.id,
          title: "Credit Note: " + creditNote.creditNoteNumber,
          status: creditNote.status,
          url: creditNote.creditNoteDocument,
          creditNoteNumber: creditNote.creditNoteNumber,
          currencyCode: creditNote.currencyCode,
        };
        this.documentCreditNoteItem = this.documentItem
        this.generalCreditNoteDocumentView = true;
      } else {
        if (!this.integrationType) {
          this.integrationType = await this.$API.getAccountingIntegration();
        }
        this.creditItem = creditNote;
        this.payment = {
          date: new Date().toISOString().split("T")[0],
          currencyCode: this.orderFinancialSetting.baseCurrency,
        };
        if (this.integrationType == "ZA_Xero") {
          this.xeroCreditNoteModal = true;
        } else {
          this.creditNoteModal = true;
        }
      }
    },

      async addPayment() {
        try {
          this.savingPayment = true;
          if (!this.invoiceItem.id) {
            this.invoiceItem.payments.push(this.payment);
          } else {
            if (!this.payment.id) {
              let result = await this.$API.createInvoicePayment(
                this.payment,
                this.invoiceItem.id
              );
              this.invoiceItem.payments.push(result);
            } else {
              let result = await this.$API.updateInvoicePayment(this.payment);
            }
          }
          this.savingPayment = false;
          this.$message.success("Payment added!");
          this.payment = {
            date: new Date().toISOString().split("T")[0],
            currencyCode: this.orderFinancialSetting.baseCurrency,
          };
        } catch (e) {
          this.savingPayment = false;
          this.$message.error("Error adding payment");
        }
      },
      addSalesItem(item = {}) {
        this.salesItem = {
          orderId: this.order.id,
          unitQuantity: 1,
          unitPrice: 0,
          total: 0,
          currency: this.orderFinancialSetting.baseCurrency,
          baseCurrency: this.orderFinancialSetting.baseCurrency,
          exchangeRate: 1,
          costExchangeRate: 1,
          baseAmount: 0,
          baseTotal: 0,
          baseCost: 0,
          unitCost: 0,
          costCurrency: this.orderFinancialSetting.baseCurrency,
          date: new Date().toISOString().split("T")[0],
          unitProfit: 0,
          ...item,
        };
        if (this.orderFinancialSetting.type == "FIXED") {
          this.salesItem.type = "FIXED";
        } else if (this.orderFinancialSetting.type == "MGP") {
          this.salesItem.type = "MGP";
        } else if (this.orderFinancialSetting.type == "FULL_CONSIGNMENT") {
          this.salesItem.type = "MARKET_RATE";
        }
        if (this.salesItems.length > 0) {
          this.salesItem.currency =
            this.salesItems.at(-1).currency ??
            this.orderFinancialSetting.baseCurrency;
          this.salesItem.costCurrency =
            this.salesItems.at(-1).costCurrency ??
            this.orderFinancialSetting.baseCurrency;
        }
        if (!this.salesItem.costCurrency) {
          this.salesItem.costCurrency =
            this.orderFinancialSetting.baseCurrency ?? "USD";
        }
        this.salesModal = true;
      },
      addSalesItemFromStock(item = {}) {
        this.assignFromStockModal = true;
      },
      checkBaseAmount(item) {
        if (item.baseCurrency == item.currency) {
          item.baseAmount = item.unitPrice;
        }
      },
      async createAccountSale() {
        try {
          this.creatingAccountSale = true;
          let suppliers = []
          for(const supplier of this.salesSuppliers) {
            if(!supplier.id) {
              suppliers.push(supplier)
            }
          }
          if(suppliers.length !== 0) {
            let response = await this.$API.createBulkChildAccountSaleSetting(suppliers)
          } 
          this.accountSales = await this.$API.createOrderAccountSale(
            this.order.id
          );
          this.creatingAccountSale = false;
          this.$message.success("Account Sale Created");
        } catch (e) {
          this.creatingAccountSale = false;
          this.$message.error("Error creating account sale");
        }
      },
      async createPackingLists() {
        try {
          this.packingListModal = false;
          this.creatingPackingList = true;
          for (let container of this.selectedContainers) {
            let result = await this.$API.createPackingList(container.id);
            container.packingListDocument = result.packingListDocument
            container.packingListDocumentId = result.packListDocumentId
            container.packingListExcelDocument = result.packingListExcelDocument;
            container.packingListExcelDocumentId = result.packingListExcelDocumentId;
          }
          this.creatingPackingList = false;
          this.$message.success("Packing List(s) Created");
        } catch (e) {
          this.creatingPackingList = false;
          this.$message.error("Error creating packing list");
        }
      },
      editBillingItem(item) {
        this.billingItem = item;
        this.billingItem.childItems = this.billingItems
          .filter((x) => x.parentBillingItemId == item.id)
          .map((x) => x.bookingContainerId);
        this.billingItemModal = true;
      },
      expandSubcategory(id) {
        if (this.expanded.includes(id)) {
          this.expanded = this.expanded.filter((exp) => exp !== id);
        } else {
          this.expanded.push(id);
        }
      },
      filterCosts(category, subcategory, parentItemId, shipmentDocumentId) {
        let result = this.billingItems.filter((cost) => cost.category === category && cost.finChargeCategoryId == subcategory && cost.parentBillingItemId === parentItemId );
        if (this.filterBookingContainerId) {
          result = this.billingItems.filter( (cost) => cost.category === category && cost.finChargeCategoryId == subcategory && cost.bookingContainerId == this.filterBookingContainerId );
        }
        if (shipmentDocumentId) {
          result = result.filter( (x) => x.shipmentDocumentId == shipmentDocumentId );
        }
        return result;
      },
      constructGroups() {
        let groupBy = [...this.groupBy];
        let result = [];
        let initialCount = groupBy.length;
        while (initialCount > 0) {
          let group = groupBy.slice(0, initialCount);
          result.push(group);
          initialCount--;
        }
        return result;
      },
  
      editSalesItem(item) {
        this.salesItem = item;
        if (this.salesItem && this.salesItem.orderSaleStocks.length > 0) {
          this.filteredPallets = this.salesItem.orderSaleStocks.map( (x) => x.stockId );
          let allStock = JSON.parse(
            JSON.stringify( [].concat.apply( [], this.containers.map((x) => x.stocks) ) )
          );
          let result = [];
          this.salesItem.orderSaleStocks.forEach((stock) => {
            let pallet = allStock.find((x) => x.id == stock.stockId);
            stock.availableQuantity = pallet.cartonQuantity - stock.quantity;
            stock.initialQuantity = stock.quantity;
            if (pallet) {
              delete pallet.id;
              stock = { ...stock, ...pallet };
              result.push(stock);
            }
          });
          this.salesItem.orderSaleStocks = result;
          this.salesItem.allocatedQuantity = this.salesItem.orderSaleStocks.reduce((a, b) => a + b.quantity, 0);
          this.salesItem.allowedQuantity = this.salesItem.orderSaleStocks.reduce((a, b) => a + b.quantity, 0) + this.salesItem.orderSaleStocks.reduce( (a, b) => a + b.availableQuantity, 0 );
          this.salesItem.totalCartons = this.salesItem.orderSaleStocks.reduce( (a, b) => a + b.cartonQuantity, 0 );
          this.filteredPalletFinancialModal = true;
        } else {
          this.salesModal = true;
        }
      },
      filterCategories(type) {
        let items = this.financeChargeItems.filter((x) => x["is" + type]).map((x) => x.finChargeCategory);
        let uniqueCategories = [...new Set(items.map((x) => x.id))];
        let result = uniqueCategories.map((x) => items.find((y) => y.id == x)).map((x) => ({ id: x.id, name: x.category }));
        result.sort((a, b) => a.name.localeCompare(b.name));
        return result;
      },
      filterObject(item, queryText, itemText) {
        return (
          item.ctoNo.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || (item.containerNo && item.containerNo .toLocaleLowerCase() .indexOf(queryText.toLocaleLowerCase()) > -1) 
        );
      },
      async getAccountSales(id) {
        this.accountSales = await this.$API.getAccountSales(id);
      },
      async getOrderInvoices(id) {
        this.orderInvoices = await this.$API.getOrderInvoices(id);
      },
      async getOrderCreditNotes(id) {
        this.orderCreditNotes = await this.$API.getOrderCreditNotes(id);
      },
      getHeaderDetail(group) {
        switch (group) {
          case "phc":
            return {
              text: "Packhouse",
              value: "phc",
              align: "center",
              initialValue: group,
            };
          case "productId":
            return {
              text: "Product",
              value: "product",
              align: "center",
              initialValue: group,
            };
          case "varietyProductId":
            return {
              text: "Variety",
              value: "varietyProduct",
              align: "center",
              initialValue: group,
            };
          case "packCode":
            return {
              text: "Packcode",
              value: "packCode",
              align: "center",
              initialValue: group,
            };
          case "grade":
            return {
              text: "Grade",
              value: "grade",
              align: "center",
              initialValue: group,
            };
          case "countSize":
            return {
              text: "Count",
              value: "countSize",
              align: "center",
              initialValue: group,
            };
          case "status":
            return {
              text: "status",
              value: "Status",
              align: "center",
              initialValue: group,
            };
          case "cartonQuantity":
            return {
              text: "No. Cartons",
              value: "cartonQuantity",
              align: "center",
              initialValue: group,
            };
          case "ctoNo":
            return {
              text: "CTO No.",
              value: "ctoNo",
              align: "center",
              initialValue: group,
            };
          case "containerNo":
            return {
              text: "Container No.",
              value: "containerNo",
              align: "center",
              initialValue: group,
            };
          default:
            return {
              text: group,
              value: group,
              align: "center",
              initialValue: group,
            };
        }
      },
      groupData() {
        let pallets = JSON.parse(
          JSON.stringify( [].concat.apply( [], this.containers.map((x) => x.stocks) ) )
        );
        let result = [];
        let groups = this.constructGroups();
        groups.reverse();
        let uniqueGroups = [];
        let total = pallets.reduce((a, b) => a + parseInt(b.cartonQuantity), 0);
        let maxLevel = 0;
        let allStockPallets = JSON.parse(
          JSON.stringify( [].concat.apply( [], this.salesItems.map((x) => x.orderSaleStocks) ) )
        );
        for (let i = 0; i < groups.length; i++) {
          for (let j = 0; j < pallets.length; j++) {
            if (!pallets[j].groups) {
              pallets[j].groups = [];
            }
            let values = groups[i].map((x) => pallets[j][x]);
            let hash = values.join(":");
            pallets[j].groups.push(hash);
            if (!uniqueGroups.includes(hash)) {
              uniqueGroups.push(hash);
            }
          }
          let productResult = [];
          for (let j = 0; j < uniqueGroups.length; j++) {
            let groupPallets = pallets.filter( (x) => x.groups[i] && x.groups[i] == uniqueGroups[j] );
            let filteredPallets = allStockPallets.filter((x) => groupPallets.map((y) => y.id).includes(x.stockId) );
            if (groupPallets.length > 0) {
              let detail = groupPallets[0];
              let parent = null;
              if (i > 0) {
                parent = detail.groups[i - 1];
              }
              maxLevel = i + 1;
              let obj = {
                level: i + 1,
                hash: uniqueGroups[j],
                parent: parent,
                cartonQuantity: groupPallets.reduce( (a, b) => a + parseInt(b.cartonQuantity), 0 ),
                palletQuantity: Math.round( groupPallets.reduce( (a, b) => a + parseFloat(b.palletQuantity), 0 ) * 100 ) / 100,
                allocatedQuantity: filteredPallets.reduce( (a, b) => a + b.quantity, 0 ),
                availableQuantity: groupPallets.reduce( (a, b) => a + parseInt(b.cartonQuantity), 0 ) - filteredPallets.reduce((a, b) => a + b.quantity, 0),
                percentage: Math.round( (groupPallets.reduce( (a, b) => a + parseInt(b.cartonQuantity), 0 ) / total) * 100 * 100 ) / 100 + "%",
              };
              for (let k = 0; k < groups.at(-1).length; k++) {
                let findGroup = this.getHeaderDetail(groups.at(-1)[k]);
                obj[findGroup.initialValue] = detail[findGroup.initialValue];
                if (!obj[findGroup.initialValue]) {
                  obj[findGroup.initialValue] = "(none)";
                }
              }
              productResult.push(obj);
            }
          }
          result = [...result, ...productResult];
        }
        for (let i = 0; i < result.length; i++) {
          result[i].children = result.filter( (x) => x.parent == result[i].hash ).length;
        }
        result = result.filter((x) => x.level == maxLevel);
        result.sort((a, b) => a.parent > b.parent ? 1 : b.parent > a.parent ? -1 : 0 );
        let fields = groups.at(-1).slice(0, groups.length - 1).map((x) => this.getHeaderDetail(x).initialValue);
        for (let i = 0; i < fields.length; i++) {
          let filteredFields = fields.filter((x, index) => index <= i);
          for (let j = 0; j < result.length; j++) {
            if (!result[j].parentHash) {
              result[j].parentHash = [];
            }
            if (!result[j].referenceHash) {
              result[j].referenceHash = [];
            }
            let values = filteredFields.map((x) => result[j][x]);
            let hash = values.join(":");
            result[j].parentHash.push(hash);
            result[j]["referenceHash" + "_" + i] = result[j].parentHash.join("_");
            result[j].referenceHash.push(result[j].parentHash.join("_"));
          }
        }
        let sortArray = result;
        let initialAlphabet = [ "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", ];
        let alphabet = [];
        for (let i = 0; i < 10; i++) {
          let lastLetter = i > 0 ? initialAlphabet[i - 1] : "";
          initialAlphabet.forEach((x) => {
            alphabet.push(lastLetter + x);
          });
        }
        for (let i = 0; i < groups.length - 1; i++) {
          let uniqueReferences = [
            ...new Set(result.map((x) => x.referenceHash[i])),
          ];
          for (let j = 0; j < uniqueReferences.length; j++) {
            let filter = result.filter(
              (x) => x.referenceHash[i] == uniqueReferences[j]
            );
            filter.sort((a, b) => a[fields[i]] > b[fields[i]] ? 1 : b[fields[i]] > a[fields[i]] ? -1 : 0 );
            for (let k = 0; k < filter.length; k++) {
              let findIndex = sortArray.findIndex(
                (x) => x.hash == filter[k].hash
              );
              if (!sortArray[findIndex]["Index"]) {
                sortArray[findIndex]["Index"] = [];
              }
              sortArray[findIndex]["Index"].push(alphabet[j]);
              sortArray[findIndex]["Index_" + i] = alphabet[j];
            }
          }
        }
        sortArray = sortArray.map((x) => ({ ...x, index: x.Index.join("") }));
        sortArray.sort((a, b) => a.index > b.index ? 1 : b.index > a.index ? -1 : 0 );
        result = sortArray;
        let uniqueParents = [...new Set(result.map((x) => x.parent))];
        let finalResult = [];
        for (let i = 0; i < uniqueParents.length; i++) {
          let filtered = result.filter((x) => x.parent == uniqueParents[i]);
          for (let j = 0; j < fields.length; j++) {
            let uniqueValues = [...new Set(filtered.map((x) => x[fields[j]]))];
            for (let k = 0; k < uniqueValues.length; k++) {
              let firstIndex = filtered.findIndex(
                (x) => x[fields[j]] == uniqueValues[k]
              );
              for (let l = 0; l < filtered.length; l++) {
                if (l == firstIndex) {
                  filtered[l][fields[j]] = uniqueValues[k];
                  filtered[l][fields[j] + "_rowspan"] = filtered.length;
                } else {
                  filtered[l][fields[j]] = null;
                }
              }
            }
          }
          finalResult = [...finalResult, ...filtered];
        }
        for (let i = 0; i < fields.length; i++) {
          let indices = [...new Set(finalResult.map((x) => x["Index_" + i]))];
          for (let j = 0; j < indices.length; j++) {
            let values = [ ...new Set( finalResult .filter((x) => x["Index_" + i] == indices[j]) .map((x) => x[fields[i]]) .filter(Boolean) ), ];
            for (let k = 0; k < values.length; k++) {
              let findFirstIndex = finalResult.findIndex(
                (x) => x["Index_" + i] == indices[j] && x[fields[i]] == values[k]
              );
              let filter = finalResult.filter(
                (x) => x["Index_" + i] == indices[j] && x[fields[i]] == values[k]
              );
              for (let l = 0; l < filter.length; l++) {
                let filterIndex = finalResult.findIndex( (x) => x.hash == filter[l].hash );
                if (filterIndex != findFirstIndex) {
                  finalResult[filterIndex][fields[i]] = null;
                  finalResult[filterIndex][fields[i] + "_rowspan"] = null;
                } else {
                  finalResult[filterIndex][fields[i] + "_rowspan"] = filter.map((x) => x[fields[i] + "_rowspan"]).filter(Boolean).reduce((a, b) => a + b);
                }
              }
            }
          }
        }
        finalResult = this.formatObjects(finalResult);
        this.groupedItems = finalResult;
      },
      availableVarieties(productId) {
        let products = [].concat.apply( [], this.containers.map((x) => x.orderCargoItems .filter((y) => y.varietyProduct && y.productId == productId) .map((y) => y.varietyProduct) ) );
        let uniqueProducts = [...new Set(products.map((x) => x.id))];
        let result = uniqueProducts.map((x) => products.find((y) => y.id == x));
        result.sort((a, b) => a.name.localeCompare(b.name));
        return result;
      },
      calculateTotal(units, price) {
        return Math.round(units * price * 100) / 100;
      },
      categoryTotal(type, category) {
        if (type === "Origin") {
          return this.originCosts.reduce((acc, cost) => {
            if (cost.category === category) {
              return acc + cost.baseAmount;
            }
            return acc;
          }, 0);
        }
      },
      orderItemStatusColor(status) {
        if (status) {
          let color = this.orderItemStatus.find(
            (x) => x.name.toLowerCase() === status.toLowerCase()
          );
          return color ? color.color : "grey";
        } else return "grey";
      },
      orderItemStatusIcon(status) {
        if (status) {
          let find = this.orderItemStatus.find(
            (x) => x.name.toLowerCase() === status.toLowerCase()
          );
          return find ? find.icon : "";
        } else return "help";
      },
      async deleteSalesItem() {
        this.deletingSalesItem = true;
        await this.$API.updateOrderSalesItems({
          id: this.salesItem.id,
          isActive: false,
          isDeleted: true,
        });
        this.deletingSalesItem = false;
        this.confirmDeleteSale = false;
        let index = this.salesItems.findIndex((x) => x.id == this.salesItem.id);
        this.salesItems.splice(index, 1);
        this.salesModal = false;
        this.filteredPalletFinancialModal = false;
        this.$message.success("Successfully removed sales item!");
      },
      documentUnitSales(id) {
        let filter = this.salesItems.filter( (x) => (x.type == "FIXED" || x.type == "MARKET_RATE") && x.shipmentDocumentId == id );
        let result = this.documentTotalSales(id) / filter.map((x) => x.unitQuantity).reduce((a, b) => a + b, 0);
        return result.toFixed(2);
      },
      documentTotalSales(id) {
        let items = this.salesItems.filter( (x) => (x.type == "FIXED" || x.type == "MARKET_RATE") && x.shipmentDocumentId == id );
        return items.filter((x) => x.shipmentDocumentId == id).map((x) => parseFloat(x.baseTotal)).reduce((a, b) => a + b, 0).toFixed(2);
      },
      documentUnitCost(id) {
        let filter = this.billingItems.filter(
          (x) => x.shipmentDocumentId == id && !x.parentBillingItemId
        );
        let result = this.documentTotalCost(id) / filter.map((x) => x.unitQuantity).reduce((a, b) => a + b, 0);
        return result.toFixed(2);
      },
      documentTotalCost(id) {
        let items = this.billingItems.filter(
          (x) => x.shipmentDocumentId == id && !x.parentBillingItemId
        );
        return items.filter((x) => x.shipmentDocumentId == id).map((x) => parseFloat(x.baseAmount)).reduce((a, b) => a + b, 0).toFixed(2);
      },
      documentTotalProfit(id) {
        return (this.documentTotalSales(id) - this.documentTotalCost(id)).toFixed( 2 );
      },
      async downloadAccountSale(item) {
        var hiddenElement = document.createElement("a");
        hiddenElement.href = item.url;
        hiddenElement.target = "_blank";
        hiddenElement.download = item.url.split("/").at(-1);
        hiddenElement.click();
      },
      async downloadPackingList(item) {
        var hiddenElement = document.createElement("a");
        hiddenElement.href = item.url;
        hiddenElement.target = "_blank";
        hiddenElement.download = item.url.split("/").at(-1);
        hiddenElement.click();
      },
      async downloadItem(item) {
        var hiddenElement = document.createElement("a");
        hiddenElement.href = item.url;
        hiddenElement.target = "_blank";
        hiddenElement.download = item.name;
        hiddenElement.click();
      },
      formatObjects(result) {
        if (this.groupBy.includes("productId")) {
          result = result.map((item) => {
            if (item.productId) {
              let findProduct = this.products.find( (product) => product.id == item.productId );
              item.productId = findProduct ? findProduct.name : null;
            }
            return item;
          });
        }
        if (this.groupBy.includes("varietyProductId")) {
          result = result.map((item) => {
            if (item.varietyProductId) {
              let findProduct = this.products.find( (product) => product.id == item.varietyProductId );
              item.varietyProductId = findProduct ? findProduct.name : null;
            }
            return item;
          });
        }
        return result;
      },
      formatDate(date) {
        let result = null;
        if (date) {
          result = dateFormat(new Date(date), "dd-mm-yyyy");
        }
        return result;
      },
      formatNumber(x, isCost = false) {
      if (isCost) {
        x *= -1;
      }
      if (x) {
        let result = parseFloat(x)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return result;
      } else if(x == 0) {
        return 0.00;
      } else {
        return null;
      }
    },
      formatNumberRate(x, isCost = false) {
        if (isCost) {
          x *= -1;
        }
        if (x) {
          let result = parseFloat(x).toFixed(4).toString()
          return result;
        } else {
          return null;
        }
      },
      getSymbol(currencyCode) {
        return getSymbolFromCurrency(currencyCode);
      },
      async loadBillingItems(id) {
        this.loadingBilling = true;
        this.billingItems = await this.$API.getOrderBillingItems(id);
        this.loadingBilling = false;
      },
      async loadSalesItems(id) {
        this.loadingSales = true;
        this.salesItems = await this.$API.getOrderSalesItems(id);
        this.groupData();
        this.loadingSales = false;
      },
      async loadFinancialSettings(id) {
        this.loading = true;
        this.orderFinancialSetting = await this.$API.getOrderFinancialSettings(
          id
        );
        this.loading = false;
      },
      async getOrderDocuments(id) {
        this.loadingOrderDocuments = true;
        this.orderDocuments = await this.$API.getOrderDocuments(id);
        this.loadingOrderDocuments = false;
      },
      documentSalesTypes(id) {
        let uniqueTypes = [ ...new Set( this.salesItems .filter((x) => x.shipmentDocumentId == id) .map((x) => x.type) ), ];
        uniqueTypes = uniqueTypes.map((x) => ({ name: x == "FIXED" ? "Fixed Price" : x == "MGP" ? "Minimum Guaranteed Price" : "Market Sales Price", value: x, }));
        return uniqueTypes;
      },
      generateInvoice(documentId = null) {
        let items = this.salesItems.filter( (x) => (x.type == "FIXED" || x.type == "MARKET_RATE") && !x.invoiceId && !x.isDiscarded );
        if (documentId) {
          items = items.filter((x) => x.shipmentDocumentId == documentId);
        }
        let invoice = {
          orderId: this.order.id,
          currencyCode: this.orderFinancialSetting.baseCurrency,
          status: "DRAFT",
          type: "INVOICE",
          totalExclVAT: 0,
          totalInclVAT: 0,
          amountDue: 0,
          vatPercentage: 15,
          date: new Date().toISOString().split("T")[0],
          dueDate: new Date().toISOString().split("T")[0],
          customerId: this.order.orderCustomerId,
          payments: [],
          invoiceLineItems: [],
        };
  
        let uniqueContainers = [ ...new Set(items.map((x) => x.bookingContainerId)), ];
        for (let i = 0; i < uniqueContainers.length; i++) {
          let containerItems = items.filter( (x) => x.bookingContainerId == uniqueContainers[i] );
          let container = this.containers.find( (x) => x.id == uniqueContainers[i] );
          if (container.containerNo) {
            invoice.reference = container.containerNo;
          }
          let containerItem = containerItems.find((x) => x.bookingContainerId == container.id)
          let lineItem = {
            type: "ACCOUNT",
            salesItemId: container.id,
            description: [container.containerNo, container.ctoNo].filter(Boolean).join(" - "),
            quantity: containerItems.map((x) => x.unitQuantity).reduce((a, b) => a + b, 0),
            unitAmount: Math.round( containerItems .map((x) => parseFloat(x.baseAmount)) .reduce((a, b) => a + b, 0) * 100 ) / 100,
            totalExclVAT: Math.round( containerItems .map((x) => parseFloat(x.baseTotal)) .reduce((a, b) => a + b, 0) * 100 ) / 100,
            currency: containerItems.at(-1).baseCurrency,
            taxType: "NONE",
          };
          lineItem.unitAmount = Math.round((lineItem.totalExclVAT / lineItem.quantity) * 100) / 100;
          lineItem.description += "\n";
          for (let j = 0; j < containerItems.length; j++) {
            let containerLineItem = { ...lineItem };
            containerLineItem.tempId = `${new Date().getTime()}/${j}`
            containerLineItem.orderSalesItemId = containerItems[j].id;
            containerLineItem.quantity = containerItems[j].unitQuantity;
            containerLineItem.unitAmount = containerItems[j].baseAmount;
            containerLineItem.totalExclVAT = containerItems[j].baseTotal;
            containerLineItem.currency = containerItems[j].baseCurrency;
            containerLineItem.totalInclVAT = containerItems[j].baseTotal;
            let description = [ containerItems[j].product ? containerItems[j].product.name : null, containerItems[j].varietyProduct ? containerItems[j].varietyProduct.name : null,
              containerItems[j].packCode ?? null,
              containerItems[j].grade ?? null,
              containerItems[j].countSize ?? null,
              containerItems[j].inventoryCode ?? null,
              containerItems[j].phc ?? null,
              containerItems[j].puc ?? null,
            ].filter(Boolean).join(" ");
  
            containerLineItem.description = description;
            if (containerItems[j].note) {
              containerLineItem.description += "Note: " + containerItems[j].note + "\n";
            }
            invoice.invoiceLineItems.push(containerLineItem);
          }
        }
        invoice.orderSalesItems = items.map((x) => x.id);
        invoice.totalInclVAT = invoice.invoiceLineItems.map((x) => x.totalInclVAT).reduce((a, b) => a + Number(b), 0)
        this.editInvoice(invoice);
      },
      generateCreditNote(documentId = null) {
      let items = this.salesItems.filter(
        (x) =>
          (x.type == "FIXED" || x.type == "MARKET_RATE") &&
          x.invoiceId &&
          x.isDiscarded
      );
      if (documentId) {
        items = items.filter((x) => x.shipmentDocumentId == documentId);
      }
      let creditNote = {
        orderId: this.order.id,
        currencyCode: this.orderFinancialSetting.baseCurrency,
        status: "DRAFT",
        type: "CREDITNOTE",
        totalExclVAT: 0,
        totalInclVAT: 0,
        amountDue: 0,
        vatPercentage: 15,
        date: new Date().toISOString().split("T")[0],
        dueDate: new Date().toISOString().split("T")[0],
        customerId: this.order.orderCustomerId,
        payments: [],
        creditNoteLineItems: [],
      };
      let uniqueContainers = [
        ...new Set(items.map((x) => x.bookingContainerId)),
      ];
      for (let i = 0; i < uniqueContainers.length; i++) {
        let containerItems = items.filter(
          (x) => x.bookingContainerId == uniqueContainers[i]
        );
        let container = this.containers.find(
          (x) => x.id == uniqueContainers[i]
        );
        if (container.containerNo) {
          creditNote.reference = container.containerNo;
        }
        let lineItem = {
          type: "CREDITNOTE",
          salesItemId: container.id,
          description: [container.containerNo, container.ctoNo]
            .filter(Boolean)
            .join(" - "),
          quantity: containerItems
            .map((x) => x.unitQuantity)
            .reduce((a, b) => a + b, 0),
          unitAmount:
            Math.round(
              containerItems
                .map((x) => parseFloat(x.baseAmount))
                .reduce((a, b) => a + b, 0) * 100
            ) / 100,
          totalExclVAT:
            Math.round(
              containerItems
                .map((x) => parseFloat(x.baseTotal))
                .reduce((a, b) => a + b, 0) * 100
            ) / 100,
          currency: containerItems.at(-1).baseCurrency,
          taxType: "NONE",
        };
        // console.log(lineItem)
        lineItem.unitAmount =
          Math.round((lineItem.totalExclVAT / lineItem.quantity) * 100) / 100;
        lineItem.description += "\n";
        for (let j = 0; j < containerItems.length; j++) {
          let containerLineItem = { ...lineItem };
          containerLineItem.quantity = containerItems[j].unitQuantity;
          containerLineItem.unitAmount = containerItems[j].baseAmount;
          containerLineItem.totalExclVAT = containerItems[j].baseTotal;
          containerLineItem.currency = containerItems[j].baseCurrency;
          containerLineItem.totalInclVAT = containerItems[j].baseTotal;
          let description = [
            containerItems[j].product ? containerItems[j].product.name : null,
            containerItems[j].varietyProduct
              ? containerItems[j].varietyProduct.name
              : null,
            containerItems[j].packCode ?? null,
            containerItems[j].grade ?? null,
            containerItems[j].countSize ?? null,
            containerItems[j].inventoryCode ?? null,
            containerItems[j].phc ?? null,
            containerItems[j].puc ?? null,
          ]
            .filter(Boolean)
            .join(" ");

          containerLineItem.description = description;
          if (containerItems[j].note) {
            containerLineItem.description +=
              "Note: " + containerItems[j].note + "\n";
          }
          creditNote.invoiceLineItems.push(containerLineItem);
        }
      }
      creditNote.orderSalesItems = items.map((x) => x.id);
      // creditNote.totalExclVAT = creditNote.invoiceLineItems
      //   .map((x) => x.totalExclVAT)
      //   .reduce((a, b) => a + b, 0);
      this.editCreditNote(creditNote);
    },

      addHeader(header) {
        header.show = true;
        this.productSummaryHeaders.unshift(header);
        this.updateSelectedHeaders();
      },
      removeHeader(header) {
        let index = this.productSummaryHeaders.findIndex( (x) => x.value == header );
        this.productSummaryHeaders[index].show = false;
        this.updateSelectedHeaders();
      },
      invoiceCreatedEvent(val) {
        if (val && val.id) {
          this.orderInvoices.push(val);
          if (val.orderSalesItems) {
            val.orderSalesItems.forEach((item) => {
              let find = this.salesItems.findIndex((x) => x.id == item);
              if (find > -1) {
                this.salesItems[find].invoiceId = val.id;
                this.salesItems[find].invoice = val;
              }
            });
          }
          this.getOrderCreditNotes(this.order.id);
        }
      },
      async creditNoteCreatedEvent(val) {
      if(val && val.id) {
        this.orderCreditNotes.push(val)
        this.filterCreditNotes(val.invoiceId)
        await this.getOrderInvoices(this.order.id)
        this.updateInvoiceDocumentItem(val.invoiceId)
      }
      // if (val && val.id) {
      //   this.orderInvoices.push(val);
      //   if (val.orderSalesItems) {
      //     val.orderSalesItems.forEach((item) => {
      //       let find = this.salesItems.findIndex((x) => x.id == item);
      //       if (find > -1) {
      //         this.salesItems[find].invoiceId = val.id;
      //         this.salesItems[find].invoice = val;
      //       }
      //     });
      //   }
      // }
    },
    async creditNoteUpdatedEvent(val) {
      if(val && val.id) {
        this.creditNoteToEdit = {}
        await this.getOrderCreditNotes(this.order.id)
        await this.getOrderInvoices(this.order.id)
        this.filterCreditNotes(val.invoiceId)
        // this.getInvoiceCredit(val.invoiceId)
        
        if(!val.isActive && val.isDeleted && val.status === "DELETED") {
          this.orderCreditNotes = this.orderCreditNotes.filter((x) => x.id !== val.id)
          this.generalCreditNoteDocumentView = false
          this.updateInvoiceDocumentItem(val.invoiceId)
        } else {
          // let oldCreditNoteIndex = this.orderCreditNotes.findIndex((x) => x.id === val.id)
          this.updateCreditNoteDocumentItem(val.id)
          this.updateInvoiceDocumentItem(val.invoiceId)
          // this.orderCreditNotes[oldCreditNoteIndex] = val
        }
        this.filCreditKey++
      }
      // if (val && val.id) {
      //   this.orderInvoices.push(val);
      //   if (val.orderSalesItems) {
      //     val.orderSalesItems.forEach((item) => {
      //       let find = this.salesItems.findIndex((x) => x.id == item);
      //       if (find > -1) {
      //         this.salesItems[find].invoiceId = val.id;
      //         this.salesItems[find].invoice = val;
      //       }
      //     });
      //   }
      // }
    },
    async invoiceUpdatedEvent(val) {
        if (val && val.id) {
          this.invoiceToEdit = {}
          await this.getOrderInvoices(this.order.id)

          if(!val.isActive && val.isDeleted && val.status === "DELETED") {
            this.salesItems = []
            this.orderInvoices = this.orderInvoices.filter((x) => x.id !== val.id)
            this.generalInvoiceDocumentView = false
          } else {
            // let oldInvoiceIndex = this.orderInvoices.findIndex((x) => x.id === val.id)
            this.updateInvoiceDocumentItem(val.id)

            // this.orderInvoices[oldInvoiceIndex] = val
          }
          // if (val.orderSalesItems && val.status !== "DELETED") {
          //   val.orderSalesItems.forEach((item) => {
          //     let find = this.salesItems.findIndex((x) => x.id == item);
          //     if (find > -1) {
          //       this.salesItems[find].invoiceId = val.id;
          //       this.salesItems[find].invoice = val;
          //     }
          //   });
          // }
          this.getOrderCreditNotes(this.order.id);
          this.loadSalesItems(this.order.id)
          // this.salesItems = await this.$API.getOrderSalesItems(this.order.id);
          // console.log(this.salesItems)
        }
      },
      updateInvoiceDocumentItem(invoiceId){
        let invoice = this.orderInvoices.find((x) => x.id === invoiceId)
        this.documentInvoiceItem = {
            id: invoice.id,
            title: "Invoice: " + invoice.invoiceNumber,
            status: invoice.status,
            url: invoice.invoiceDocument,
            invoiceNumber: invoice.invoiceNumber,
            amountCredited: invoice.amountCredited,
            currencyCode: invoice.currencyCode
          };
          this.documentItem = this.documentInvoiceItem
      },
      updateCreditNoteDocumentItem(creditNoteId){
        let creditNote = this.orderCreditNotes.find((x) => x.id === creditNoteId)
        this.documentCreditNoteItem = {
            id: creditNote.id,
            title: "Credit Note: " + creditNote.creditNoteNumber,
            status: creditNote.status,
            url: creditNote.creditNoteDocument,
            creditNoteNumber: creditNote.creditNoteNumber,
            currencyCode: creditNote.currencyCode
          };
          this.documentItem = this.documentCreditNoteItem
      },
      invoiceColor(status) {
        switch (status) {
          case "PAID":
            return "success";
          case "SUBMITTED":
            return "blue";
          case "DRAFT":
            return "orange";
          default:
            return "grey";
        }
      },
      async saveInvoice() {
        try {
          this.savingInvoice = true;
          if (this.invoiceItem.totalExclVAT && this.invoiceItem.vatPercentage) {
            this.invoiceItem.totalInclVAT = Math.round(parseFloat(this.invoiceItem.totalExclVAT) * 100) / 100 + (Math.round(parseFloat(this.invoiceItem.totalExclVAT) * 100) / 100) * (parseFloat(this.invoiceItem.vatPercentage) / 100);
          }
          if (this.invoiceItem.payments.length > 0) {
            this.invoiceItem.amountDue = this.invoiceItem.totalInclVAT - this.invoiceItem.payments.reduce( (a, b) => a + parseFloat(b.amount), 0 );
          } else {
            this.invoiceItem.amountDue = this.invoiceItem.totalInclVAT;
          }
          if (this.invoiceItem.id) {
            let result = await this.$API.updateOrderInvoice(this.invoiceItem);
            let find = this.orderInvoices.findIndex((x) => x.id == result.id);
            if (find > -1) {
              this.orderInvoices[find] = result;
            }
          } else {
            let result = await this.$API.createOrderInvoice(
              this.invoiceItem,
              this.order.id
            );
            this.orderInvoices.push(result);
          }
          this.savingInvoice = false;
          this.invoiceModal = false;
        } catch (e) {
          this.savingInvoice = false;
          this.$message.error("Error saving invoice");
        }
      },
      async saveSalesItem() {
        this.discardReasonDialog = false;
        if (this.salesItem.isDiscarded && !this.salesItem.note) {
          this.discardReasonDialog = true;
        } else {
          this.savingSalesItem = true;
          if (this.salesItem.isDiscarded) {
            this.salesItem.unitPrice = 0;
            this.salesItem.baseAmount = 0;
          }
          this.salesItem.total = this.calculateTotal( this.salesItem.unitQuantity, this.salesItem.unitPrice );
          if (this.salesItem.baseCurrency !== this.salesItem.currency) {
            this.salesItem.exchangeRate = parseFloat( (1 / this.inverseExchangeRate).toFixed(this.decimalPrimary) );
          }
          if (this.salesItem.baseCurrency !== this.salesItem.costCurrency) {
            this.salesItem.costExchangeRate = parseFloat( (1 / this.inverseCostExchangeRate).toFixed(this.decimalPrimary) );
          }
          if (this.salesItem.baseCurrency == this.salesItem.currency) {
            this.salesItem.baseAmount = this.salesItem.unitPrice;
          }
          if (this.salesItem.costCurrency == this.salesItem.baseCurrency) {
            this.salesItem.baseCost = this.salesItem.unitCost;
          }
          this.salesItem.baseTotal = this.calculateTotal( this.salesItem.unitQuantity, this.salesItem.baseAmount );
          this.salesItem.baseTotalCost = this.calculateTotal( this.salesItem.unitQuantity, this.salesItem.baseCost );
          if (this.salesItem.baseCost && this.salesItem.baseAmount) {
            this.salesItem.unitProfit = Math.round( (this.salesItem.baseAmount - this.salesItem.baseCost) * 100 ) / 100;
            this.salesItem.totalProfit = (Math.round( (this.salesItem.baseAmount - this.salesItem.baseCost) * 100 ) / 100) * this.salesItem.unitQuantity; }
          if (this.salesItem.orderSaleStocks) {
            this.salesItem.orderSaleStocks =
            this.salesItem.orderSaleStocks.filter( (x) => parseInt(x.quantity) > 0 );
          }
          if (this.salesItem.supplierId) {
            let findSupplier = this.organisations.data.find( (x) => x.relatedOrganisationId == this.salesItem.supplierId );
            if (findSupplier) {
              this.salesItem.supplier = findSupplier.relatedOrganisation;
            }
          }
          if (!this.salesItem.id) {
            let result = await this.$API.createOrderSalesItems(this.salesItem);
            this.salesItems.push(result);
          } else {
            let result = await this.$API.updateOrderSalesItems(this.salesItem);
            let find = this.salesItems.findIndex((x) => x.id == result.id);
            if (find > -1) {
              this.salesItems[find] = result;
            }
          }
          this.ufsc++
          this.fss++
          this.salesModal = false;
          this.filteredPalletFinancialModal = false;
          this.filteredPallets = [];
          this.savingSalesItem = false;
        }
      },
      setAccountingContactId() {
        if (this.invoiceItem.customerId) {
          let findRelationship = this.organisations.data.find( (x) => x.relatedOrganisationId == this.invoiceItem.customerId );
          if (findRelationship) {
            this.invoiceItem.contactId = findRelationship.accountingId;
          } else {
            this.invoiceItem.contactId = null;
          }
        } else {
          this.invoiceItem.contactId = null;
        }
      },
      async submitFiles() {
        let pending = this.fileList.filter((x) => !x.loading);
        for (let i = 0; i < pending.length; i++) {
          pending[i].loading = true;
          let url = await URL.createObjectURL(pending[i].file);
          pending[i].fileIndex = this.lastIndex;
          this.toDataUrl(url, (data) => {
            let obj = {
              name: pending[i].name,
              data: data,
              type: pending[i].type,
              fileIndex: pending[i].fileIndex,
            };
            this.submitUpload(obj);
          });
          this.lastIndex++;
        }
      },
      async submitUpload(obj) {
        let Api = axios.create({
          baseURL:
            process.env.NODE_ENV == "production"
              ? "https://api.loglive.ai"
              : process.env.NODE_ENV == "staging"
              ? "https://api.loglive.ai"
              : "http://localhost:3000",
          headers: {
            Authorization: `${this.$store.state.token}`,
            apikey: `${this.$store.state.orgToken}`,
          },
        });
        let result = await Api.post("/order/upload/" + this.order.id, obj, {
          onUploadProgress: (progressEvent) => {
            let find = this.fileList.findIndex( (x) => x.fileIndex == obj.fileIndex );
            if (find > -1) {
              this.fileList[find].progress = Math.ceil( (progressEvent.loaded / progressEvent.total) * 100 );
            }
          },
        });
        result = result.data;
        this.orderDocuments.push(result);
        let find = this.fileList.findIndex((x) => x.fileIndex == obj.fileIndex);
        this.fileList.splice(find, 1);
        this.$message.success("Successfully uploaded " + obj.name + "!");
        this.documentKey++;
      },
      toDataUrl(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
          var reader = new FileReader();
          reader.onloadend = function () {
            callback(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      },
      updateSelectedHeaders() {
        this.productSummaryHeaders = this.allHeaders.filter(
          (x) => x.show || x.required
        );
        this.updateHeaderSelection();
        this.productKey++;
      },
      updateHeaderSelection() {
        this.groupBy = this.productSummaryHeaders.filter((x) => x.group && x.show).map((x) => x.value);
        this.productKey++;
        this.groupData();
      },
      updateSelectedTotals() {
        if (this.salesItem.id) {
          let allowedTotal =
            this.salesItem.orderSaleStocks.map((x) => parseInt(x.initialQuantity)).reduce((a, b) => a + b, 0) +
            this.salesItem.orderSaleStocks.map((x) => parseInt(x.availableQuantity)).reduce((a, b) => a + b, 0);
          if (this.salesItem.allocatedQuantity > allowedTotal) {
            this.salesItem.allocatedQuantity = allowedTotal;
            this.$message.error( "Allocated quantity cannot be more than total quantity" );
            return;
          } else {
            this.salesItem.unitQuantity = this.salesItem.allocatedQuantity;
          }
        }
        let total = parseInt(this.salesItem.unitQuantity);
        let setZero = this.salesItem.orderSaleStocks.filter( (x) => !this.filteredPallets.includes(x.stockId) );
        setZero.forEach((x) => { x.quantity = 0; });
        this.salesItem.orderSaleStocks.filter((x) => this.filteredPallets.includes(x.stockId)).forEach((x) => {
            let totalAvailable = this.salesItem.id ? x.availableQuantity + x.initialQuantity : x.initialQuantity;
            if (total > 0) {
              if (total >= totalAvailable) {
                x.quantity = totalAvailable;
                x.availableQuantity = 0;
                total -= x.quantity;
              } else {
                x.quantity = total;
                x.availableQuantity = totalAvailable - x.quantity;
                total = 0;
              }
            } else {
              x.quantity = 0;
            }
          });
        this.salesItem.allocatedQuantity = this.salesItem.orderSaleStocks.map((x) => x.quantity).reduce((a, b) => a + b, 0);
        this.stockRefreshKey++;
      },
      updateUnitInput() {
        let allowedTotal = this.salesItem.allowedQuantity;
        if (this.salesItem.unitQuantity > allowedTotal) {
          this.salesItem.unitQuantity = allowedTotal;
          this.$message.error(
            "Allocated quantity cannot be more than total available quantity"
          );
        }
        let total = parseInt(this.salesItem.unitQuantity);
        let setZero = this.salesItem.orderSaleStocks.filter((x) => !this.filteredPallets.includes(x.stockId) );
        setZero.forEach((x) => { x.quantity = 0; });
        this.salesItem.orderSaleStocks.filter((x) => this.filteredPallets.includes(x.stockId)).forEach((x) => {
            let totalAvailable = this.salesItem.id
              ? x.availableQuantity + x.initialQuantity
              : x.initialQuantity;
            if (total > 0) {
              if (total >= totalAvailable) {
                x.quantity = totalAvailable;
                x.availableQuantity = 0;
                total -= x.quantity;
              } else {
                x.quantity = total;
                x.availableQuantity = totalAvailable - x.quantity;
                total = 0;
              }
            } else {
              x.quantity = 0;
            }
          });
        this.salesItem.allocatedQuantity = this.salesItem.orderSaleStocks.map((x) => x.quantity).reduce((a, b) => a + b, 0);
        this.stockRefreshKey++;
      },
  
      updateInputTotals() {
        this.salesItem.allocatedQuantity = this.salesItem.orderSaleStocks.map((x) => parseInt(x.quantity)).reduce((a, b) => a + b, 0);
        if (this.salesItem.id) {
          let allowedTotal =
            this.salesItem.orderSaleStocks.map((x) => parseInt(x.initialQuantity)).reduce((a, b) => a + b, 0) + 
            this.salesItem.orderSaleStocks.map((x) => parseInt(x.availableQuantity)).reduce((a, b) => a + b, 0);
          if (this.salesItem.allocatedQuantity > allowedTotal) {
            this.salesItem.allocatedQuantity = allowedTotal;
            this.$message.error( "Allocated quantity cannot be more than total quantity" );
          } else {
            this.salesItem.unitQuantity = this.salesItem.allocatedQuantity;
          }
        }
        this.stockRefreshKey++;
      },
      async updateDocument(item) {
        this.savingDocument = true;
        let result = await this.$API.updateShipmentDocument(item);
        this.savingDocument = false;
        this.documentViewModal = false;
      },
      viewDetail(item, headerValue) {
        let keys = Object.keys(item);
        keys = keys.filter( (x) => this.displayHeaders.map((y) => y.value).includes(x) && x != "cartonQuantity" && x != "palletQuantity" && x != "availableQuantity" );
        if ( headerValue != "palletQuantity" && headerValue != "cartonQuantity" && headerValue != "availableQuantity" ) {
          keys = keys.filter((x, i) => i <= keys.indexOf(headerValue));
        }
        let allStocks = JSON.parse( JSON.stringify( [].concat.apply( [], this.containers.map((x) => x.stocks) ) ) );
        let hashDetail = item.hash.split(":");
        let filterObject = {};
        keys.forEach((key, index) => {
          filterObject[key] = hashDetail[index];
          this.salesItem[key] = hashDetail[index];
        });
        let result = allStocks.filter((x) =>
          keys.every((key) => {
            if (filterObject[key] == "") {
              return x[key] == null || x[key] == "";
            } else {
              return x[key] == filterObject[key];
            }
          })
        );
        let currentStock = JSON.parse( JSON.stringify( [].concat.apply( [], this.salesItems.map((x) => x.orderSaleStocks) ) ) );
        result.forEach((x) => {
          let find = currentStock.find((y) => y.stockId == x.id);
          x.availableQuantity = x.cartonQuantity - (find ? find.quantity : 0);
          x.initialQuantity = x.availableQuantity;
        });
        result = result.filter((x) => x.availableQuantity > 0);
        this.filteredPallets = result.map((x) => x.id);
        let currency = this.orderFinancialSetting.baseCurrency;
        let exchangeRate = 1;
        if (this.salesItems.length > 0) {
          currency = this.salesItems.at(-1).currency;
          exchangeRate = this.salesItems.at(-1).exchangeRate;
        }
        this.salesItem = {
          orderId: this.order.id,
          unitQuantity: result.map((x) => x.availableQuantity).reduce((a, b) => a + b, 0),
          unitPrice: 0,
          total: 0,
          currency: currency,
          exchangeRate: 1,
          baseCurrency: this.orderFinancialSetting.baseCurrency,
          baseAmount: 0,
          baseTotal: 0,
          date: new Date().toISOString().split("T")[0],
          orderSaleStocks: result,
          unitCost: 0,
          totalCost: 0,
          costCurrency: this.orderFinancialSetting.baseCurrency,
          totalCartons: result.map((x) => x.availableQuantity).reduce((a, b) => a + b, 0),
          ...filterObject,
        };
        if (this.salesItems.length > 0) {
          this.salesItem.currency = this.salesItems.at(-1).currency ?? this.orderFinancialSetting.baseCurrency;
          this.salesItem.costCurrency = this.salesItems.at(-1).costCurrency ?? this.orderFinancialSetting.baseCurrency;
        }
        this.salesItem.allowedQuantity = this.salesItem.unitQuantity;
        if (this.documentItem && this.documentItem.id) {
          this.salesItem.shipmentDocumentId = this.documentItem.id;
          this.salesItem.reference = this.documentItem.reference;
          this.salesItem.date = this.documentItem.date;
        }
        let uniqueContainers = [...new Set(result.map((x) => x.bookingContainerId))];
        if (uniqueContainers.length == 1) {
          this.salesItem.bookingContainerId = uniqueContainers[0];
        }
        let uniqueCommodities = [...new Set(result.filter((x) => x.productId).map((x) => x.productId))];
        if (uniqueCommodities.length == 1) {
          this.salesItem.productId = uniqueCommodities[0];
        }
        let uniqueVarieties = [...new Set( result.filter((x) => x.varietyProductId).map((x) => x.varietyProductId) )];
        if (uniqueVarieties.length == 1) {
          this.salesItem.varietyId = uniqueVarieties[0];
        }
        this.salesItem.orderSaleStocks.forEach((x) => {
          x.stockId = x.id;
          x.quantity = x.availableQuantity;
          delete x.id;
        });
        this.salesItem.allocatedQuantity = this.salesItem.orderSaleStocks.map((x) => x.availableQuantity).reduce((a, b) => a + b, 0);
        if (this.orderFinancialSetting.type == "FIXED") {
          this.salesItem.type = "FIXED";
        } else if (this.orderFinancialSetting.type == "MGP") {
          this.salesItem.type = "MGP";
        } else if (this.orderFinancialSetting.type == "FULL_CONSIGNMENT") {
          this.salesItem.type = "MARKET_RATE";
        }
        if (this.salesItem.orderSaleStocks.length == 0) {
          this.$message.error("No available stock to allocate");
        } else {
          this.assignFromStockModal = false;
          this.filteredPalletFinancialModal = true;
        }
      },
      updateSelectedContainers() {
        this.selectedContainers = this.containers.filter((x) => x.selected);
      },
      viewDocument(item) {
        this.documentItem = item;
        this.documentViewModal = true;
      },
      async getCurrentRate(currencyFrom, currencyTo){
        if(currencyFrom !== currencyTo) {
          this.loadingCurrency = true
          let rate = `${currencyFrom}/${currencyTo}`
          let res = await this.$API.getLatestExchangeRate({rate: rate})
          this.currentExchangeRate = parseFloat(res.rate.toFixed(this.decimalPrimary))
          this.loadingCurrency = false
        } else {
          this.currentExchangeRate = 1
        }
      },
      async getCurrentRateCost(currencyFrom, currencyTo) {
        this.loadingCurrencyCost = true;
        let rate = `${currencyFrom}/${currencyTo}`;
        let res = await this.$API.getLatestExchangeRate({ rate: rate });
        this.currentExchangeRateCost = parseFloat(
          res.rate.toFixed(this.decimalPrimary)
        );
        this.loadingCurrencyCost = false;
      },
      applyCurrentRate() {
        this.inverseExchangeRate = this.currentExchangeRate
        this.exKey++
      },
      applyCurrentRateForSupplier() {
        this.currentSupplier.exchangeRate = parseFloat(1 / this.currentExchangeRate)
        this.supplierExchangeRate = this.currentExchangeRate
        this.exKey++
      },
      applyCurrentRateCost() {
        this.inverseCostExchangeRate = this.currentExchangeRateCost;
        this.excKey++;
      },
    },
  };
  </script>
  <style scoped>
  * {
  font-size: 12px;
  --darkLayer: var(--v-greyDarker-base) !important;
  --lighterLayer: var(--v-greyRaised-lighten1) !important;
}
  .stop-wrap {
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .v-list-item {
    min-height: 38px;
    height: auto;
    width: 100%;
    max-height: 72px !important;
    padding: 0 0px !important;
    display: inline-flex;
}
::v-deep .dialogRight {
  position: fixed !important;
  right: 0 !important;
}
.toolbars ::v-deep .v-toolbar__content {
  padding: 0;
}
.v-list-item__avatar {
    margin: 0 2px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;

}
.v-list-item__action {
    margin: 0 2px 0 0 !important;
    height: 30px !important;
    display: flex !important;
    place-content: center !important;
}
.v-list-item__title {
    font-size: 0.75rem !important;
    line-height: 1.4 !important;
}

.v-list-item__subtitle {
    font-size: 0.625rem !important;
    opacity: 0.7 !important;
}

.sideDrawer {
    background-color: var(--v-greyRaised-base) !important;
    height: calc(100% - 36px) !important;
    min-height: calc(100% - 36px) !important;
    width: 18vw;
    box-shadow: -10px 0 10px -5px rgba(0, 0, 0, 0.5);
    min-width: 18vw;
    max-width: 50%;
    z-index: 10;
    right: -18vw;
    top: 66px;
    position: fixed;
    transition: right 0.2s ease-in-out;
    margin-top: -30px !important;
}

.sideDrawer.show {
    right: 36px;
}
::v-deep .dialogRightOnInvoice {
  position: fixed !important;
  left: 50vw !important;
}
.creditNotesList {
  max-height: 60vh;
  overflow-y: auto;
}
  </style>